import React, { useEffect, useRef } from "react";


const Popup = ({open, onClose, children, className, onToggle, controls=[]}) => {

    const ref = useRef(null);
    //const openRef = React.useRef(open);

    /*useEffect(() =>{
        document.addEventListener("click", handleClick, true);
    }, [])

    useEffect(()=> {
        console.log('popup useEffect open', open)
        openRef.current = open;
        console.log('popup useEffect openRef.current', openRef.current)
    }, [open])
    
  

    const handleClick = (e) => {

        const controlsRefs = controls.map((control) => {
            return control.current
        })

        //console.log('e.target', e.target)
        //console.log('controlsRefs', controlsRefs)

        if(!ref?.current?.contains(e.target)){
            console.log('!!openRef.current onClose', !!openRef.current)
            !!openRef.current && onToggle();
        }
    }
    */


    if(open){
        return (
            <div
                className={className}
                ref={ref}

            >
                {children}
            </div>
        )
    }
    else{
        return null
    }
}

export default Popup;