import React, { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
//import "./ResetPassword.scss"
import ResetPasswordForm from "../../../components/ResetPassword/ResetPassword";
import './ResetPassword.scss'
export default function ResetPassword() {

  const passwordRef = useRef();
  const previousPasswordRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);

  const handleSubmit = () => {
    dispatch(userActions.putPassword(auth, previousPasswordRef.current, passwordRef.current));
  };

  return (
    <div
      className='account-reset-password-container'
    >
      <ResetPasswordForm
        passwordRef={passwordRef}
        previousPasswordRef={previousPasswordRef}
        onSubmit={handleSubmit}
        requirePreviousPassword={true}
        theme="dark"
      />
    </div>
  );
}
