//import { StyleSheet } from "react-native";
import { theme } from "./theme";

const globalStyles = {
  bottomArch: {
    width: "100%",
    height: "100%",
    //paddingLeft: 15,
    //paddingRight: 15,
    //borderColor:"blue",
    //borderWidth: 1,

  },  

  iconTeal: {
    color: theme.teal,
  },
  iconNaomiBlue: {
    color: theme.naomiBlue,
  },

  //Midwest Styles below thise point
  // Page
  container: {
    //width: "100%",
    //paddingLeft: 15,
    //paddingRight: 15,
    //backgroundColor: theme.black,
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
    width: "100%",
    height: "100%",
  },
  main: {
    flex: 1,
    width: "72%",
    paddingHorizontal: (theme.verticalSpacing * 1.5),
    paddingTop: (theme.verticalSpacing * 1.25),
    backgroundColor: theme.white,
  },
  aside: {
    width: "28%",
    padding: theme.verticalSpacing,
    backgroundColor: theme.offWhite,
  },
  // Grid
  grid: {
    marginHorizontal: -(theme.horizontalSpacing * .5),
  },
  bottomSpacing: {
    marginBottom: theme.verticalSpacing,
  },
  topSpacing: {
    marginTop: theme.verticalSpacing,
  },
  horizontalSpacing: {
    marginHorizontal: (theme.horizontalSpacing * .5),
  },
  // Text
  bold: {
    //fontFamily: "SourceSansPro_900Black",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  hr: {
    marginVertical: theme.verticalSpacing,
    borderBottomWidth: 1,
    borderBottomColor: theme.lightGrey,
  },
  h1: {
    fontSize: 27,
    lineHeight: 32,
    //fontFamily: "SourceSansPro_900Black",
    marginBottom: 15,
  },
  h2: {
    fontSize: 27,
    lineHeight: 32,
    //fontFamily: "SourceSansPro_900Black",
    marginBottom: 15,
  },
  h3: {
    fontSize: 21,
    lineHeight: 25,
    ////fontFamily: "SourceSansPro_900White",
    color: theme.white,
    marginBottom: 15,
  },
  h4: {
    fontSize: 18,
    lineHeight: 21,
    //fontFamily: "SourceSansPro_900Black",
    marginBottom: 15,
  },
  bodyText: {
    fontSize: 16,
    fontFamily: theme.Nunito,
    color: theme.white,
  },
  // Page Header
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingVertical: 20,
    paddingHorizontal: 25,
    backgroundColor: theme.darkGrey,
  },
  heading: {
    color: theme.white,
    fontSize: 27,
    //fontFamily: "SourceSansPro_900Black",
  },
  headerNavigation: {
    display: "flex",
    flexDirection: "row",
    marginRight: -15,
  },
  headerNavigationButton: {
    display: "flex",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 12,
    borderLeftWidth: 1,
    borderLeftColor: "#777777",
  },
  headerNavigationButtonLast: {
    borderLeftWidth: 0,
    borderLeftColor: "transparent",
  },
  headerNavigationIcon: {
    color: theme.teal,
    height: 40,
    fontSize: 40,
    lineHeight: 40,
  },
  headerNavigationText: {
    color: "#999999",
    textTransform: "uppercase",
    fontSize: 11,
    lineHeight: 15,
  },
  headerNavigationActive: {
    color: theme.white,
  },
  headerNavigationHighlight: {
    width: "100%",
    height: 3,
    backgroundColor: theme.white,
    marginTop: 6,
    marginBottom: -12,
  },
  headerButton: {
    display: "flex",
    alignItems: "center",
    paddingVertical: 5,
    paddingLeft: 25,
    borderLeftWidth: 1,
    borderLeftColor: "#777777",
  },
  headerButtonText: {
    color: theme.white,
    marginVertical: 3,
    textTransform: "uppercase",
  },
  headerNavigationButtonAlt: {
    borderLeftColor: theme.white,
    paddingHorizontal: theme.verticalSpacing,
  },
  headerNavigationActiveAlt: {
    backgroundColor: theme.white,
    height: 89,
    marginVertical: -(theme.btnPadding),
    marginRight: -1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headerNavigationIconAlt: {
    color: "#434343",
  },
  headerNavigationTextAlt: {
    color: "#434343",
  },
  headerNavigationSubmit: {
    backgroundColor: theme.green,
    paddingVertical: 5,
    paddingHorizontal: 15,
    marginVertical: -(theme.btnPadding),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // Forms
  formLabel: {
    color: theme.lightGrey,
    marginBottom: 6,
    fontSize: 15,
    lineHeight: 18,
    fontFamily: "Nunito",
  },
  formLabelError: {
    color: theme.red,
    //fontFamily: "SourceSansPro_600SemiBold",
  },
  formTextInput: {
    fontSize: 22,
    fontFamily: "Nunito",
    //padding: theme.inputPadding,
    backgroundColor: "transparent",
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.white,
  },
  formTextInputError: {
    borderBottomColor: theme.red,
  },
  formTextarea: {
    fontSize: 18,
    lineHeight: 27,
    paddingHorizontal: theme.inputPadding,
    paddingVertical: (theme.inputPadding * 1.5),
    backgroundColor: theme.white,
    borderWidth: 1,
    borderColor: theme.inputBorderColor,
    //fontFamily: "SourceSansPro_400Regular",
    marginBottom: theme.verticalSpacing,
    textAlignVertical: "top",
  },
  formSwitchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 18,
    lineHeight: 20,
    //fontFamily: "SourceSansPro_400Regular",
    paddingHorizontal: theme.inputPadding,
    paddingVertical: (theme.inputPadding - 6),
    //backgroundColor: theme.black,
    borderBottomWidth: 1,
    borderBottomColor: theme.inputBorderColor,
  },
  formSwitchLabel: {
    marginHorizontal: 10,
  },
  formFeedback: {
    color: theme.red,
    textAlign: "left",
    height: 20,
    fontFamily: theme.font,
    alignSelf: "flex-start"
  },
  formFeedbackDropdown: {
    marginTop: -theme.verticalSpacing,
    marginBottom: (theme.verticalSpacing - 20),
  },
  formError: {
    width: "100%",
    height: "auto",
    textAlign: "center",
    marginTop: -10,
    marginBottom: theme.verticalSpacing,
    borderColor: theme.red,
    borderWidth: 1,
    padding: theme.btnPadding,
    color: theme.red,
    //fontFamily: "SourceSansPro_400Regular",
  },
  // Buttons
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    padding: theme.btnPadding,
  },
  btnNoamiBlue: {
    backgroundColor: theme.naomiBlue,
  },
  btnLightNaomiBlue: {
    backgroundColor: theme.lightNoamiBlue,
  },
  btnTeal: {
    backgroundColor: theme.teal,
  },
  btnGreen: {
    backgroundColor: theme.green,
  },
  btnGrey: {
    backgroundColor: theme.grey,
  },
  btnDarkGrey: {
    backgroundColor: theme.darkGrey,
  },
  btnActive: {
    shadowColor: theme.darkGrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: .45,
    shadowRadius: 2,
    elevation: 2,
  },
  btnRedOutline: {
    color: theme.red,
    padding: theme.btnPadding - 1,
    borderWidth: 1,
    borderColor: theme.red,
    backgroundColor: "transparent",
  },
  btnTealOutline: {
    color: theme.teal,
    padding: theme.btnPadding - 1,
    borderWidth: 1,
    borderColor: theme.teal,
    backgroundColor: "transparent",
  },
  btnGreenOutline: {
    color: theme.teal,
    padding: theme.btnPadding - 1,
    borderWidth: 1,
    borderColor: theme.green,
    backgroundColor: "transparent",
  },
  btnGreyOutline: {
    padding: theme.btnPadding - 1,
    borderWidth: 1,
    borderColor: theme.inputBorderColor,
    backgroundColor: "transparent",
  },
  btnLightGreyOutline: {
    padding: theme.btnPadding - 1,
    borderWidth: 1,
    borderColor: theme.lightGrey,
    backgroundColor: "transparent",
  },
  btnStacked: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: -(theme.btnPadding * .5),
    marginHorizontal: -(theme.btnPadding * .125),
  },
  btnCentered: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.white,
    fontSize: 15,
    lineHeight: 17,
    fontFamily: "Nunito",
  },
  btnTextLight: {
    color: theme.white,
  },
  btnTextRed: {
    color: theme.red,
  },
  btnTextTeal: {
    color: theme.teal,
  },
  btnTextGreen: {
    color: theme.green,
  },
  btnTextDark: {
    color: theme.black,
  },
  btnTextLightGrey: {
    color: theme.lightGrey,
  },
  // Dropdown
  dropDownPicker: {
    backgroundColor: theme.offWhite,
    borderWidth: 0,
  },
  dropDownPickerContainer: {
    height: 50,
    marginBottom: theme.verticalSpacing,
    backgroundColor: theme.offWhite,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.inputBorderColor,
  },
  dropDownPickerDropDown: {
    borderColor: theme.inputBorderColor,
    fontSize: 15,
    lineHeight: 20,
    paddingHorizontal: 0,
    paddingVertical: 0,
    maxHeight: 500,
    //fontFamily: "SourceSansPro_400Regular",
  },
  dropDownPickerItem: {
    justifyContent: "flex-start",
    fontSize: 15,
    lineHeight: 18,
    paddingHorizontal: theme.inputPadding,
    //fontFamily: "SourceSansPro_400Regular",
  },
  dropDownPickerLabel: {
    paddingVertical: 5,
    paddingHorizontal: 0,
    fontSize: 15,
    lineHeight: 18,
    //fontFamily: "SourceSansPro_400Regular",
  },
  dropDownPickerSelectedLabel: {
    //fontFamily: "SourceSansPro_600SemiBold",
  },
  dropDownPickerPlaceholder: {
    color: "#aaaaaa",
    fontSize: 18,
    lineHeight: 22,
    paddingVertical: 12,
  },
  dropDownPickerActiveItem: {
    backgroundColor: theme.offWhite,
  },
  dropDownPickerActiveLabel: {
    //fontFamily: "SourceSansPro_600SemiBold",
  },

  // Modal
  modal: {
    flex: 1,
    width: "66%",
    height: "80%",
    maxHeight: "80%",
    minHeight: (theme.height * .8),
    marginHorizontal: "17%",
    shadowColor: theme.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.35,
    shadowRadius: 10,
    elevation: 2,
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.white,
    paddingHorizontal: 75,
    paddingVertical: 45,
  },
  modalText: {
    color: theme.black,
    fontSize: 25,
    lineHeight: 30,
    marginBottom: (theme.verticalSpacing * .25),
    //fontFamily: "SourceSansPro_400Regular",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: theme.offWhite,
    paddingHorizontal: theme.verticalSpacing,
    paddingVertical: theme.verticalSpacing,
  },
  modalBG: {
    flex: 1,
    maxHeight: theme.height,
    justifyContent: "flex-end",
    bottom: "10%",
  },
  // Date Picker
  datePicker: {
    position: "absolute",
    zIndex: 3000,
    left: 0,
    right: 0,
    backgroundColor: theme.white,
  },
  datePickerWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingBottom: (theme.verticalSpacing * 1.5),
  },
  datePickerHeader: {
    width: "100%",
    backgroundColor: theme.lightGrey,
    marginBottom: 0,
    paddingVertical: (theme.verticalSpacing * .5),
    paddingHorizontal: 20,
  },
  datePickerContainer: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: theme.offWhite,
    height: 345,
  },
  datePickerElement: {
    backgroundColor: theme.offWhite,
    width: 350,
    height: 330,
  },
  datePickerControls: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: theme.verticalSpacing,
    backgroundColor: theme.offWhite,
  },
  // Callout Box
  callout: {
    padding: 20,
    borderRadius: 6,
    backgroundColor: theme.darkGrey,
    marginBottom: theme.verticalSpacing,
  },
  calloutBody: {
    fontSize: 16,
    lineHeight: 24,
    //fontFamily: "SourceSansPro_400Regular",
    color: theme.white
  },
  // Listing
  listing: {
    //backgroundColor: theme.offWhite,
    //marginBottom: (theme.verticalSpacing * .5),
    //shadowColor: theme.darkGrey,
    //shadowOffset: {
     // width: 1,
      //height: 1,
    //},
    //shadowOpacity: .25,
    //shadowRadius: 2,
    //elevation: 1,
    //zIndex: 1,
    //display: "flex",
    //flexDirection: "column",
    //justifyContent: "space-between",
  },
  listingItem: {
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //paddingLeft: 10,
    //paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    //backgroundColor: theme.offWhite,
  },
  listingHr:{
    flexDirection: "row",
    height: 5
  },
  listingData: {
    maxWidth: "58%",
  },
  listingHeading: {
    fontSize: 25,
    lineHeight: 32,
    marginBottom: (theme.verticalSpacing * .33),
    //fontFamily: "SourceSansPro_900Black",
  },
  listingMeta: {
    display: "flex",
    flexDirection: "row",
    marginHorizontal: -(theme.horizontalSpacing * .5),
  },
  listingMetaText: {
    fontSize: 15,
    lineHeight: 18,
    marginHorizontal: (theme.horizontalSpacing * .5),
    //fontFamily: "SourceSansPro_400Regular",
  },
  listingMetaTextStacked: {
    display: "flex",
    flexDirection: "column",
    paddingRight: (theme.horizontalSpacing * 1.5),
    marginRight: theme.horizontalSpacing,
    borderRightWidth: 1,
    borderRightColor: theme.black,
  },
  listingMetaLabel: {
    fontSize: 15,
    lineHeight: 18,
    textTransform: "uppercase",
    //fontFamily: "SourceSansPro_400Regular",
  },
  listingControlGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-end",
  },
  listingExpandControl: {
    padding: theme.btnPadding,
    maxWidth: 105,
    backgroundColor: theme.grey,
    marginVertical: -theme.btnPadding,
    marginRight: -theme.btnPadding,
    marginLeft: theme.horizontalSpacing,
  },
  listingExpandControlText: {
    textTransform: "uppercase",
    color: theme.black,
    fontSize: 16,
    lineHeight: 24,
    textAlign: "center",
    //fontFamily: "SourceSansPro_400Regular",
  },
  listingExpandControlIcon: {
    color: theme.red,
    fontSize: 16,
    textAlign: "center",
  },
  listingAdditional: {
    borderTopWidth: 1,
    borderTopColor: theme.grey,
    borderBottomWidth: 1,
    borderBottomColor: theme.grey,
  },
  backgroundDark: {
    backgroundColor: theme.darkGrey,
  },
  backgroundLight: {
    backgroundColor: theme.lightGrey,
  },
}

export default globalStyles;