import { theme } from "../../assets/theme";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingLeft: 15,
        paddingRight: 15,
        //borderStyle: 'solid',
        //borderColor: 'red',
        //boderWidth: 1,
    },
    playerControls: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        marginTop: 30,
        marginBottom: 5
    },
    playerControlIcon: {
        //flexGrow: 1,
        color: theme.naomiBlue,
        fontSize: 35,
    },
    playerToolIcon: {
        //flexGrow: 1,
        color: theme.naomiBlue,
        fontSize: 35,
    },
    progressTimeContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    }
}

export default styles;