import { forgotPasswordConstants } from "../constants";
import { forgotPasswordService } from "../services/forgotPasswordService";
import { alertActions } from "./alertActions";

export const forgotPasswordActions = {
    postPasswordCode,
    postPassword,
    setPasswordCode,
    clear
};


function postPasswordCode(username) {
    return (dispatch) => {
        //dispatch(dispatch(alertActions.request()));
        forgotPasswordService.postPasswordCode(username)
            .then((res) => {
                if (res.success) {
                    dispatch(success());
                    dispatch(setUsername(username));
                    dispatch(alertActions.success("Code has been sent."));
                }
                else {
                    const errorMessage = "Something went wrong.";
                    throw errorMessage;
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };
    function success() {
        return { type: forgotPasswordConstants.POST_PASSWORD_CODE_SUCCESS};
    }
    function failure() {
        return { type: forgotPasswordConstants.POST_PASSWORD_CODE_FAILURE };
    }
}

function postPassword(username, password, passwordCode) {
    return (dispatch) => {
        //dispatch(dispatch(alertActions.request()));
        forgotPasswordService.postPassword( username, password, passwordCode )
            .then((res) => {
                if (res.success) {
                    dispatch(success())
                    dispatch(alertActions.success("Your password has been updated."));
                }
                else {
                    const errorMessage = "Something went wrong.";
                    throw errorMessage;
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };
    function success() {
        return { type: forgotPasswordConstants.POST_PASSWORD_SUCCESS };
    }
    function failure() {
        return { type: forgotPasswordConstants.POST_PASSWORD_FAILURE };
    }
}

function setPasswordCode(code) {
    return { type: forgotPasswordConstants.SET_PASSWORD_CODE, code: code }
}
function clear(){
    return {type: forgotPasswordConstants.CLEAR_FORGOT_PASSWORD}
}

function setUsername(username) {
    return { type: forgotPasswordConstants.SET_USERNAME, username };
}