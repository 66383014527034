import axios from "axios";
//import oauth from "axios-oauth-client";
import { api } from "../utilities/api";

const postPasswordCode = async (username) => {

  //let authToken = {};
  //console.log(authParams)
  const apiDomain = api.url;
  //console.log(apiDomain+"/api/v1/loginSubmit")
  //const httpsAgent = new https.Agent({rejectUnauthorized: false})

  return await axios.post(apiDomain + "/account/password/code", { "username": username }, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-API-KEY": api.apiKey
    },
    //httpsAgent 
  }).then((res) => res.data
  ).catch((error) => {
    console.log(error);
    return error;
  });
}

const postPassword = async (username, password, passwordCode) => {

  //let authToken = {};
  //console.log(authParams)
  const apiDomain = api.url;
  //console.log(apiDomain+"/api/v1/loginSubmit")
  //const httpsAgent = new https.Agent({rejectUnauthorized: false})

  const params = { 
    "username": username, 
    "password": password, 
    "passwordCode": passwordCode 
  };
  return await axios.post(apiDomain + "/account/password", params, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-API-KEY": api.apiKey
    },
    //httpsAgent 
  }).then((res) => res.data
  ).catch((error) => {
    console.log(error);
    return error;
  });
}

export const forgotPasswordService = {
  postPasswordCode,
  postPassword
};