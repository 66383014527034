import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
//import logo from './logo.svg';
import './App.scss';
import './assets/theme.scss'; 
import Login from './pages/Login/Login'
//import Home from "./pages/Home/Home";
import PageLayout from "./components/PageLayout/PageLayout";
import LoginLayout from "./components/LoginLayout/LoginLayout";
import Audio from "./pages/Audio/Audio";
import Profile from "./pages/Profile/Profile";
import Notes from "./pages/Notes/Notes";
import SubmitUsername from "./pages/ForgotPassword/SubmitUsername/SubmitUsername";
import AccountResetPassword from "./pages/Account/ResetPassword/ResetPassword";


import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
//import { viewActions } from "./actions";
//import Navigation from './components/Navigation/Navigation'
import Upload from './pages/Upload/Upload'
import EnterCode from "./pages/ForgotPassword/EnterCode/EnterCode";
import ForgotPasswordResetPassword from "./pages/ForgotPassword/ResetPassword/ResetPassword";
import NewUserResetPassword from "./pages/Login/ResetPassword/ResetPassword";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from "./assets/theme"
import { alertActions } from "./actions";

//import MobileNavigation from './components/MobileNavigation/MobileNavigation'


function App() {

  const auth = useSelector((state) => state.authentication)
  const user = useSelector((state) => state.user)
  const alert = useSelector((state) => state.alert);
  //const view = useSelector((state) => state.view)
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const appContainer = useRef()
  const dispatch = useDispatch();
  /*const [dimensions, setDimensions] = useState({ 
    width: window.innerWidth
  })

  

  useEffect(() => {

    function handleResize() {
      setDimensions({
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
  })
*/
  /*useEffect(() => {

    if (auth.hasOwnProperty("userId")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [auth]);
  */
  /*
    useLayoutEffect(()=>{
      if (appContainer.current.offsetWidth < 769 && (!view.isMobile || !view.hasOwnProperty('isMobile'))){
        dispatch(viewActions.setIsMobile(true));
      }
      else if (appContainer.current.offsetWidth > 769 && (view.isMobile || !view.hasOwnProperty('isMobile'))){
        dispatch(viewActions.setIsMobile(false));
      }
    },[appContainer, dimensions])
  
  */
  useEffect(() => {
    if (alert?.type == 'success') {
      toast(alert.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { color: theme.white, background: theme.accentColor },
        progressStyle: { background: theme.white },
      });
      dispatch(alertActions.clear())
    }
    else if (alert?.type == 'error') {
      toast(alert.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { color: theme.white, background: theme.red },
        progressStyle: { background: theme.white },
      });
      dispatch(alertActions.clear())
    }
  }, [alert]);

  const authCheck = ( destination ) => {
    if(!!auth?.token && !!user?.userId){
      return destination
    }
    else if(auth?.challenge?.challengeName == 'NEW_PASSWORD_REQUIRED'){
      return <Navigate to="/login/reset-password" />
    }
    else{
      return <Navigate to="/login" />
    }
  }

  return (

    <div
      className="App"
      ref={appContainer}

    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={authCheck(<PageLayout />)} >
            <Route index element={<Audio />} />
            <Route path="audio" element={<Audio />} />
            <Route path="upload" element={<Upload />} />
            <Route path="notes/:fileId" element={<Notes />} />
          </Route>
          <Route path="login" element={<LoginLayout />} >
            <Route index element={<Login />} />
            <Route path="reset-password" element={<NewUserResetPassword />} />
          </Route>
          <Route path="forgot-password" element={<LoginLayout />} >
            <Route index element={<SubmitUsername />} />
            <Route path="submit-username" element={<SubmitUsername />} />
            <Route path="enter-code" element={<EnterCode />} />
            <Route path="reset-password" element={<ForgotPasswordResetPassword />} />
          </Route>
          <Route path="account" element={authCheck(<PageLayout />)} >
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="reset-password" element={<AccountResetPassword />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>

  );
}

export default App;
//{isLoggedIn ? <Home /> : <Redirect to="/login" />}
//<Route exact path="/" element={true || isLoggedIn ? <Home /> : <Navigate to="/login" replace/>} />
//<Route path="/login" element={<Login />} />

/*function Parent() {
  
  return (
    <div style={{
        height: '100%'
    }}>
          this is a parent
          <Outlet />
    </div>
  );
}
*/
/*function Profile() {
  
  return (
    <div >
      this is a profile
    </div>
  );
}*/