import { notesConstants } from "../constants";
import { notesService } from "../services/";
import { alertActions } from "./alertActions";

export const notesActions = {
  submitNotes,
  getNotes,
  getFileIdsFromNotes
};

function submitNotes(_id = "", notes, auth,  fileId, fileName, type='general', tags = null, startTime = null, endTime = null) {
  
  return (dispatch) => {
    //dispatch(alertActions.request())
    const service = !!_id ? notesService.putNotes : notesService.postNotes; 
    service(_id, notes, auth,  fileId, fileName, type, tags, startTime, endTime)
    .then((res) => {
      if (res.data.success) {
        dispatch(getNotes(auth, fileId));
        dispatch(alertActions.success("Your note has been saved."));
      }
      else{
        const errorMessage = "Something went wrong.";
        throw errorMessage;
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch(alertActions.error(error));
      dispatch(failure());
    });
  }

  function success(){
    return {}
  }
  function failure(){
    return {}
  }
}

function getNotes(auth, fileId) {
  return (dispatch) => {
    notesService.getNotes(auth, fileId)
    .then((res) => {
      if (res.data.success) {
        dispatch(setNotes({"notes": res.data.notes, fileId}))
        dispatch(notesActions.getFileIdsFromNotes(auth));
      }
      else{
        const errorMessage = "Something went wrong.";
        throw errorMessage;
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch(dispatch(alertActions.failure(error)));
    });
  }
}

function setNotes(notes){
  return { type: notesConstants.SET_NOTES, notes };
}

function getFileIdsFromNotes(auth) {
  return (dispatch) => {
    notesService.getFileIdsFromNotes(auth)
    .then((res) => {
      if (!!res?.data?.success){
        dispatch(setFileIdsFromNotes(res.data.fileIds))
      } 
    },
    (error) => {
      console.log(error)
      //dispatch(failure(error));
    });
  }
}
function setFileIdsFromNotes(fileIds){
  return { type: notesConstants.SET_FILEIDS_FROM_NOTES, fileIds };
}
