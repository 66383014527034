import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { } from "../../../actions";
import { fileService, utilityService } from "../../../services";
import { alertActions, playerActions } from "../../../actions";
//import Waveform from '../../../assets/images/Waveform.svg';
//import EqualizerWave  from "../../AnimatedGifs/EqualizerWave";
import Equalizer from '../../../assets/images/Equalizer.svg';
import EqualizerWave from '../../../assets/images/EqualizerWave.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faEllipsisV, faDownload } from '@fortawesome/free-solid-svg-icons'
import globalStyles from "../../../assets/global.style";
import NotesIconWhite from '../../../assets/images/NotesIconWhite.png'
import NotesIconNB from '../../../assets/images/NotesIconNB.png'
import { theme } from "../../../assets/theme";
import { useNavigate } from "react-router-dom";
import "./AudioListItem.scss"
import { saveAs } from "file-saver";
import Drawer from 'react-modern-drawer';


const AudioListItem = ({ _id, date, fileName, path, practiceDate, size, time, type }) => {
    const dispatch = useDispatch();
    //const user = useSelector((state) => state.user);
    //const network = useSelector((state) => state.network);
    const player = useSelector((state) => state.player);
    const notes = useSelector((state) => state.notes);
    const auth = useSelector((state) => state.authentication);
    const [controlIsOpen, setControlIsOpen] = useState(false)
    const navigate = useNavigate();
    const formattedTime = utilityService.formatTime(time).replace(/^0+/, '').replace(/^:+/, '')
    
    return (
        <div>
            <div>
                <div
                    className="listing-item"
                >

                    <div
                        className="equalizer-container"
                        onClick={() => {
                            setControlIsOpen(true);
                        }}
                    >
                        {_id == player.details._id ? player.controls.isPlaying ? <img src={EqualizerWave} className="equalizer" /> : <img src={Equalizer} className="equalizer" /> :
                            <FontAwesomeIcon
                                icon={faEllipsisV}
                                style={{
                                    color: controlIsOpen ? theme.naomiBlue : theme.white,
                                    marginRight: 15,
                                    //color: '#995eff'
                                }}
                            />}
                        {false ? <FontAwesomeIcon icon={faPlay} style={globalStyles.iconNaomiBlue} /> : null}

                        {/*<WebView className="equalizer" source={EqualizerWave} /<Equalizer  className="equalizer" /> this is the non moving equalizer image*/}

                    </div>
                    <div
                        className="title-container"
                    >
                        <button
                            style={{
                                backgroundColor: 'rgba(0,0,0,0)',
                                borderStyle: 'none',
                            }}
                            onClick={() => {
                                if (!player.controls.isPlaying || _id != player.details._id) {
                                    dispatch(playerActions.setCurrent(
                                        {
                                            "_id": _id,
                                            fileName,
                                            path,
                                            date,
                                            size,
                                            type,
                                            practiceDate,
                                            time,
                                        },
                                        true
                                    ))
                                }
                            }}
                        /*onTouchStart={() => {
                            console.log('touch start')
                        } }
                        onTouchEnd={() => {
                            console.log('touch end')
                        } }
                        onMouseDown={() => {
                            console.log('mouse down')
                        }}
                        onMouseUp={() => {
                            console.log('mouse up')
                        }}*/
                        >

                            <div
                                style={{
                                    fontSize: _id == player.details._id ? globalStyles.bodyText.fontSize * 1.1 : globalStyles.bodyText.fontSize,
                                    fontFamily: theme.font,
                                    color: _id == player.details._id ? theme.naomiBlue : theme.white,
                                    borderStyle: 'none',
                                }}
                            >
                                <h4>{fileName}</h4>
                            </div>
                        </button>
                    </div>
                    <div
                        className="note-container"
                    >
                        <button
                            style={{
                                backgroundColor: 'rgba(0,0,0,0)',
                                borderStyle: 'none',
                            }}
                            onClick={() => {
                                navigate("/notes/" + _id)
                            }}
                        >
                            <img
                                style={{ width: 15, height: 15 }}
                                src={!!notes?.fileIdsFromNotes && Array.isArray(notes.fileIdsFromNotes) && notes?.fileIdsFromNotes?.includes(_id) ? NotesIconNB : NotesIconWhite}
                            />
                        </button>
                    </div>
                    <div
                        className="time-container"
                    >
                        <div style={{
                            fontSize: 12,
                            fontFamily: theme.Nunito,
                            color: theme.white,
                            //marginRight: 15,
                        }}>
                            {formattedTime}
                        </div>
                    </div>
                </div>
                {/*<img src={HR} className="HR"/>*/}
                {/*<img src={HRMobile} className="HRMobile"/>*/}
                <hr className="audio-item-hr"/>
            </div>
            <Drawer
                open={controlIsOpen}
                onClose={() => setControlIsOpen(false)}
                direction='right'

            >
                <div
                    className='file-controls'
                >
                    <button
                        onClick={async () => {
                            try {
                                const url = await fileService.getFileUrl(_id, auth);
                                if (!!url) {
                                    saveAs(
                                        url,
                                        fileName
                                    )
                                }
                            } catch (error) {
                                console.log(error);
                                alertActions.error('Something went wrong');
                            }
                        }}
                        className="btnText btn-file-control"
                    >
                        <FontAwesomeIcon icon={faDownload} className="control-icon" />
                        Download
                    </button>
                </div>
            </Drawer>
        </div>
    );
};

export default memo(AudioListItem)