import React, { useState, useEffect } from 'react';
import "./ResetPassword.scss"
import PasswordInput from "../PasswordInput/PasswordInput"

export default function ResetPassword({ onSubmit, passwordRef, previousPasswordRef, requirePreviousPassword = false, theme = 'light', showTitle = true, title }) {

  const [showPasswordValidationMessage, setShowPasswordValidationMessage] = useState(false);
  const [showPreviousPasswordValidationMessage, setShowPreviousPasswordValidationMessage] = useState(false);
  const [showPasswordConfirmValidationMessage, setShowPasswordConfirmValidationMessage] = useState(false);
  const [previousPassword, setPreviousPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  useEffect(() => {
    if (!!passwordRef) { passwordRef.current = password }
  }, [password])

  useEffect(() => {
    if (!!previousPasswordRef) { previousPasswordRef.current = previousPassword }
  }, [previousPassword])

  const handleSubmit = () => {
    setShowPasswordConfirmValidationMessage(passwordConfirm != password);
    setShowPasswordValidationMessage(!password);
    setShowPreviousPasswordValidationMessage(!previousPassword);
    ((passwordConfirm == password && !!password) && (!requirePreviousPassword || !!previousPassword)) && onSubmit();
  };

  const Title = () => {
    if (showTitle) {
      return (
        <div
          className='title dark-title'
        >
          {!!title ? title : 'Reset Password'}
        </div>
      );
    }
    else {
      return null;
    }
  }
  return (
    <>
      <Title />
      <div className="form">
        <PasswordInput
          label="Enter Current Password"
          placeholder="Enter your current password"
          enabled={requirePreviousPassword}
          value={previousPassword}
          onChange={(value) => {
            setPreviousPassword(value)
          }}
          showError={showPreviousPasswordValidationMessage}
          theme={theme}
        />
        <PasswordInput
          label="Enter Password"
          placeholder="Enter your new password"
          value={password}
          onChange={(value) => {
            setPassword(value);
          }}
          showError={showPasswordValidationMessage}
          errorMessage="Password is required"
          theme={theme}
          enabled={true}
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm your new password"
          value={passwordConfirm}
          onChange={(value) => {
            setPasswordConfirm(value);
          }}
          showError={showPasswordConfirmValidationMessage}
          errorMessage="Passwords do not match"
          theme={theme}
          enabled={true}
        />
        <div
          className="form-group"
        >
          <button
            onClick={handleSubmit}
            className={`button ${theme == 'dark' ? 'light-button' : 'light-button'}`}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
