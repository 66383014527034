import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordActions } from "../../../actions";
import "./ResetPassword.scss"
import {
  useNavigate
} from "react-router-dom";
import ResetPasswordForm from "../../../components/ResetPassword/ResetPassword";
export default function ForgotPasswordResetPassword() {

  const passwordRef = useRef();
  const dispatch = useDispatch();
  const forgotPassword = useSelector((state) => state.forgotPassword);
  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(forgotPasswordActions.postPassword(forgotPassword.username, passwordRef.current, forgotPassword.passwordCode));
  };

  useEffect(() => {
    !!forgotPassword.postPasswordSuccess && navigate("/login");
    !!forgotPassword.postPasswordSuccess && dispatch(forgotPasswordActions.clear());
  }, [forgotPassword.postPasswordSuccess]);

  return (

    <>
      <ResetPasswordForm
        passwordRef={passwordRef}
        onSubmit={handleSubmit}
      />
      <div className="forgot-password" onClick={() => {
        dispatch(forgotPasswordActions.clear());
        navigate("/login");
      }}>
        Back to Login
      </div>
    </>
  );
}
