import { viewConstants, userConstants } from "../constants";

export function view(state = {
  isNavOpen: false
}, action) {
  switch (action.type) {
    case viewConstants.CURRENT:
      return {
        ...state,
        current: action.currentView,
      };

    case userConstants.LOGOUT:
      return {};
    case viewConstants.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile
      }
    case viewConstants.TOGGLE_NAVIGATION:
      return {
        ...state,
        isNavOpen: !state.isNavOpen
      }
    default:
      return state;
  }
}