import React from "react";
import PalindromesLogo from "../../assets/images/PalindromesLogo.png";
import ApolloLogo from "../../assets/images/ApolloLogoWhite.png";

//import './Home.scss';

import {
  Outlet
} from "react-router-dom";
import './LoginLayout.scss'

export default function LoginLayout() {

  return (
    <div className="login-layout-container">
      <div
        className="login-container"
      >
        <div className="form">
          {/* <img
          //style={styles.logo}
          className="logo"
          src={PalindromesLogo}
        />*/}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              className="logo login-logo"
              src={ApolloLogo}
            />
          </div>

          <Outlet />
        </div>
      </div>
    </div >
  );
}
