import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../assets/theme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { viewActions, userActions } from "../../actions";
import './Header.scss';
import AccountPopup from "../Popup/Popup";

const Header = () => {

    const dispatch = useDispatch();
    const [showAccountPopup, setShowAccountPopup] = useState(false);
    const toggleShowAccountPopup = () => {
        //console.log('toggleShowAccountPopup')

        setShowAccountPopup(prevState => !prevState)
    };
    const user = useSelector(state => state.user)
    const refProfileIcon = useRef();

    useEffect(() => {
        //console.log('header showAccountPopup', showAccountPopup)
    }, [showAccountPopup])

    /*const myAccountOnClick = () => {
        //console.log('my-account icon setShowAccountPopup(true)', !showAccountPopup)
        //!showAccountPopup && setShowAccountPopup(true);
        console.log('my-account icon  !showAccountPopup', !showAccountPopup)
        console.log('showAccountPopup', showAccountPopup)
        !showAccountPopup && toggleShowAccountPopup();
        
    }*/
    return (
        <>
            <div
                className="header-container"
            >
                <div
                    onClick={() => {
                        dispatch(viewActions.toggleNavigation());
                    }}
                >
                    <FontAwesomeIcon
                        icon={faBars}
                        style={{
                            color: theme.white,
                            marginLeft: 15
                        }}

                    />

                </div>
                <div
                    //onClick={ myAccountOnClick }
                    onClick={toggleShowAccountPopup}
                    ref={refProfileIcon}
                >
                    <FontAwesomeIcon
                        icon={faUser}
                        style={{
                            color: theme.white,
                            marginRight: 15
                        }}
                    />
                </div>
            </div>
            <AccountPopup
                className="account-popup"
                open={showAccountPopup}
                initialState={false}
                onToggle={(toggleShowAccountPopup)}
                onClose={() => {
                    //console.log('AccountPopup setShowAccountPopup(false);')
                    //setShowAccountPopup(false);
                }}
                controls={[refProfileIcon]}
            //direction='top'
            >
                <div
                    className="my-account-popup-item"
                    style={{ fontSize: '22px', color: '#995eff' }}
                >
                    <strong>Welcome, {!!user?.firstName ? user?.firstName : !!user?.name ? user?.name : user?.username}.</strong>
                </div>
                <hr
                    className="my-account-popup-hr"
                />
                <div
                    className="my-account-popup-item"
                    style={{ fontSize: '18px' }}
                >
                    <strong>MY ACCOUNT:</strong>
                </div>
                <div
                    className="my-account-popup-item"
                    style={{ marginLeft: '10px' }}

                >
                    <a href="/account/profile" className="link nav-link nav-link-dark">Profile</a>
                </div>
                <hr
                    className="my-account-popup-hr"
                />
                <div
                    className="my-account-popup-item"
                    style={{ fontSize: '18px' }}
                >
                    <strong>SECURITY:</strong>
                </div>
                <div
                    className="my-account-popup-item"
                    style={{ marginLeft: '10px' }}
                >
                    <a href="/account/reset-password" className="link nav-link nav-link-dark">Reset Password</a>
                </div>
                <button
                    onClick={() => {
                        dispatch(userActions.logout());
                        //navigate("/login");
                        //window.location.reload();
                    }}
                    className="btnText my-account-popup-item btn-profile-popup-logout"
                >
                    Log Out
                </button>
            </AccountPopup>
        </>
    )
}

export default Header;