import { playerConstants } from "../constants";

export const playerActions = {
    setCurrent,
    setIsPlaying,
    setAudioRef,
}

function setIsPlaying(isPlaying){
    return { type: playerConstants.SET_IS_PLAYING, isPlaying}
}

function setCurrent(details, shouldPlay=false) {
    //console.log('setCurrent')
    return { type: playerConstants.SET_CURRENT, details, shouldPlay };
}

function setAudioRef(audioRef) {
    return { type: playerConstants.SET_AUDIO_REF, audioRef };
}