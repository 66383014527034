import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notesActions, viewActions } from "../../actions";
//import { FontAwesome } from "@expo/vector-icons";
import { theme } from "../../assets/theme"
import SaveWhite from "../../assets/images/SaveWhite.png"
import SaveNB from "../../assets/images/SaveNB.png"
//import XOut from "../../assets/images/XOut.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActivityIndicator from 'react-activity-indicator'
import 'react-activity-indicator/src/activityindicator.css'
import './Notes.scss'
//var sanitizer = require('sanitize')();

export default function Notes({ route }) {

  const [generalNotes, setGeneralNotes] = useState(null);
  const [generalId, setGeneralId] = useState('');
  const [isSavingGeneral, setIsSavingGeneral] = useState(true);
  const [isLoadingGeneral, setIsLoadingGeneral] = useState(true);
  const [shouldToast, setShouldToast] = useState(false);
  const [fileName, setFileName] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const view = useSelector((state) => state.view);
  const auth = useSelector((state) => state.authentication);
  const notes = useSelector((state) => state.notes);
  const audioList = useSelector((state) => state.audio.list);
  let params = useParams();
  const fileId = params.fileId;



  useEffect(() => {

    if (notes.general !== undefined) {
      setGeneralNotes(notes.general.note)
      setGeneralId(notes.general._id)
      setIsSavingGeneral(false)
    }
  }, [notes.general])

  useEffect(() => {
    if (fileId == notes.fileId) setIsLoadingGeneral(false)

  }, [notes.fileId])

  useEffect(() => {
    if (fileId != notes.fileId) dispatch(notesActions.getNotes(auth, fileId));
  }, [])

  useEffect(() => {

    var arrayLength = audioList.length;
    for (var i = 0; i < arrayLength; i++) {
      if (audioList[i]._id == fileId) {
        setFileName(audioList[i].fileName);
        break;
      }
      //Do something
    }
  }, [audioList])
  return (
    <div
      className="container notes-container"
    >
      <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", position: 'relative', marginBottom: theme.verticalSpacing }}>

        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{ color: theme.naomiBlue, height: '2em', width: '2em' }}
          onClick={() => {
            navigate(-1)
          }}
        />
        {/*<img
            src={XOut}
            style={{
              height: 20,
              width: 20,
            }}
          /> */}
        \      </div>
      <div className="fileName">{fileName}</div>
      <div >
        <div className='form-group'>
          <div className='form-label'>Notes</div>
          {isLoadingGeneral
            ? <ActivityIndicator activeColor={theme.naomiBlue} style={{ alignSelf: 'center' }} />
            : <textarea
              className='text-area'
              //selectionColor={theme.naomiBlue}
              onChange={(e) => setGeneralNotes(e.currentTarget.value)}
              value={generalNotes}
              placeholder={'Add notes here...'}
            />}
          <div
            className='notes-controls'
          >
            <div className={`save-reminder ${generalNotes == notes?.general?.note || isSavingGeneral ? '' : 'opacity'}`}>Don't forget to save your changes</div>
            {!isLoadingGeneral
              ? isSavingGeneral
                ? <ActivityIndicator size="large" activeColor={theme.naomiBlue} style={{ alignSelf: 'flex-end' }} />
                : generalNotes == notes.general
                  ? <img src={SaveWhite} className='save-btn' />
                  : <button
                    className='save-btn'
                    onClick={() => {
                      setIsSavingGeneral(true)
                      //setShouldToast(true)
                      dispatch(notesActions.submitNotes(generalId, generalNotes, auth, fileId, fileName, 'general'));
                    }}
                  >
                    <img src={SaveNB} />
                  </button>
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}


