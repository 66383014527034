import React, { useState, useEffect } from "react";

//import { theme } from "../../assets/theme"

import { useDispatch, useSelector } from "react-redux";
import { userActions, alertActions } from "../../actions";
import "./Login.scss";

import {
  useNavigate
} from "react-router-dom";
import PasswordInput from "../../components/PasswordInput/PasswordInput"



export default function Login({ navigation }) {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const auth = useSelector((state) => state.authentication);
  const user = useSelector((state) => state.user);
  const [securePassword, setSecurePassword] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    setShouldValidate(true);
    setSubmitted(true);

    if (inputs.username && inputs.password) dispatch(userActions.login(inputs.username, inputs.password, false, false));
  }

  useEffect(() => {
    //dispatch(alertActions.clear());
  }, []);

  useEffect(() => {
    if (((!!auth?.token && !!user?.userId) || auth?.challenge?.challengeName == 'NEW_PASSWORD_REQUIRED') && submitted === true) {
      setInputs({
        username: "",
        password: "",
      });
      setSubmitted(false);
      setShouldValidate(false);
      navigate("/");
    }
  }, [auth, user]);

  return (
    <>
      <div
        className="title"
      >
        LOGIN
      </div>
      <div className="form-group">
        {shouldValidate && !inputs.username ? <div className="form-validation-error">Username or email is required</div> : null}
        <input
          type="text"
          className={`form-text-input ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}`}
          autoCapitalize="none"
          autoFocus={false}
          placeholder="Enter your username or email"
          value={inputs.username}
          onChange={(e) => {
            let usernameValue = e.target.value;
            setInputs((inputs) => ({ ...inputs, username: usernameValue.trim() }));
            if (submitted) dispatch(alertActions.clear());
            setSubmitted(false);
          }}
        />
        <div className="form-label">Username or Email</div>
      </div>
      <PasswordInput
        label="Enter Password"
        placeholder="Enter your password"
        value={inputs.password}
        onChange={(passwordValue) => {
          setInputs((inputs) => ({ ...inputs, password: passwordValue.trim() }));
          if (submitted) dispatch(alertActions.clear());
          setSubmitted(false);
        }}
        showError={shouldValidate && !inputs.password}
        errorMessage="Password is required"
        enabled={true}
      />
      <button
        className="button"
        onClick={handleSubmit}
      >
        Login
      </button>
      <div className="forgot-password" onClick={() => {
        navigate("/forgot-password");
      }}>
        Forgot Password
      </div>
    </>
  );
}