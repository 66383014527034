import { notesConstants, userConstants } from "../constants";

export function notes(state = {}, action) {
  switch (action.type) {
    case notesConstants.SET_NOTES:
      let generalNotes = {};
      let publicNotes = {};
      let personalNotes = {};

      if(action.notes.notes != undefined){
        for (const [key, note] of Object.entries(action.notes.notes)) {

          if (note.type == 'general') generalNotes = note;
          if (note.type == "personal") personalNotes = {
            ...personalNotes,
            note
          }
          if (note.type == "personal") publicNotes = {
            ...publicNotes,
            note
          }

        }
      }

      return {
        ...state,
        "personal": personalNotes,
        "general": generalNotes,
        "public": publicNotes,
        "fileId": action.notes.fileId
      };

    case notesConstants.SET_FILEIDS_FROM_NOTES:

      return{
        ...state,
        "fileIdsFromNotes": action.fileIds
      }
      case userConstants.LOGOUT:
        return {};
    default:
      return state;
  }
}