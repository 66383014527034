import { userConstants } from "../constants";

const initialState = {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action?.auth?.token,
        accessToken: action?.auth?.accessToken,
        challenge: action?.auth?.challenge
      };
    case userConstants.LOGIN_FAILURE:
      return {};

    case userConstants.LOGOUT:
      return {};
    case userConstants.POST_PASSWORD_SUCCESS:
      const modifiedState = state;
      delete modifiedState?.challenge;
      return {
        ...modifiedState,
        token: action?.auth?.token,
        accessToken: action?.auth?.accessToken,
      }
    case userConstants.PUT_PASSWORD_SUCCESS:
      return {
        ...state,
        token: action?.auth?.token,
        accessToken: action?.auth?.accessToken,
      };
    default:
      return state;
  }
}

