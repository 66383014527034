export const userConstants = {
    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
    GETUSER_SUCCESS: "GETUSER_SUCCESS",
    MODIFY_SUCCESS: "MODIFY_SUCCESS",
    MODIFY_FAILURE: "MODIFY_FAILURE",
    LOGOUT: "LOGOUT",
    GET_TENANT_SUCCESS: "GET_TENANT_SUCCESS",
    POST_PASSWORD_SUCCESS: "POST_PASSWORD_SUCCESS",
    POST_PASSWORD_FAILURE: "POST_PASSWORD_FAILURE",
    PUT_PASSWORD_SUCCESS: "PUT_PASSWORD_SUCCESS",
    PUT_PASSWORD_FAILURE: "PUT_PASSWORD_FAILURE"
  };