import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
//import { Audio } from 'expo-av';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    //faBackward,
    //faForward,
    faPlay,
    faRedoAlt,
    faStepBackward,
    faStepForward,
    faUndoAlt,
    faPause,
    faRepeat,
    faArrowLeft,
    faGauge,
    faMinus,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import globalStyles from "../../assets/global.style";
import styles from './Player.style';
//import ProgressBar from '../../assets/images/ProgressBar.svg';
//import { api } from "../../utilities/api";
import { fileService, utilityService } from "../../services";
import { playerActions } from "../../actions";
import './Player.scss'
import Slider from 'rc-slider';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css'
import BottomStrip from "../../assets/images/BottomStrip.png"
import useLongPress from "../../utilities/useLongPress";
//import Modal from '../Modal/Modal'
//import Drawer from 'react-modern-drawer';
import { theme } from "../../assets/theme";
//import { motion as m } from "framer-motion"



//const { Handle } = Slider;


//import Constants from 'expo-constants';

/*const _loadNewPlaybackInstance = async (playing) => {
    if (playbackInstance != null) {
        await playbackInstance.unloadAsync();
        playbackInstance.setOnPlaybackStatusUpdate(null);
        playbackInstance = null;
    }

    const source = { uri: PLAYLIST[this.index].uri };
    const initialStatus = {
        shouldPlay: playing,
        rate: this.state.rate,
        volume: this.state.volume,
    };

    const { sound, status } = await Audio.Sound.create(
        source,
        initialStatus,
        this._onPlaybackStatusUpdate
    );
    playbackInstance = sound;

    this._updateScreenForLoading(false);
}*/

export default function Player() {

    /***Audio controls ***/

    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSeeking, setIsSeeking] = useState(false);
    const [seekValue, setSeekValue] = useState(0);
    const [playbackInstancePosition, setPlaybackInstancePosition] = useState(0);
    const [playbackInstanceDuration, setPlaybackInstanceDuration] = useState(0);
    const [playbackTrim, setPlaybackTrim] = useState(0);
    const player = useSelector((state) => state.player);
    const auth = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    const audioList = useSelector((state) => state.audio.list);
    const [playerStatusLoaded, setPlayerStatusLoaded] = useState(false)

    /***End Audio controls ***/

    /***LOOPER ***/
    const [looperVisible, setLooperVisible] = useState(false);
    const toggleLooperVisible = () => {
        looperVisibleRef.current = !looperVisibleRef.current;
        setLooperVisible(prevState => !prevState);
    }
    const looperVisibleRef = useRef(false)


    const [looperActive, setLooperActive] = useState(false);
    const toggleLooperActive = () => {
        looperActiveRef.current = !looperActiveRef.current;
        setLooperActive(prevState => !prevState);
    }
    const looperActiveRef = useRef(false)


    //const [looperStart, setLooperStart] = useState(0);
    //const [looperEnd, setLooperEnd] = useState(0);
    const looperStartRef = useRef(0)
    const looperEndRef = useRef(0)


    const [looperStartHour, setLooperStartHour] = useState("");
    const [looperStartHourPos, setLooperStartHourPos] = useState(0);
    const looperStartHourRef = useRef();
    const [looperStartHourPosTrig, setLooperStartHourPosTrig] = useState(false);


    const [looperStartMin, setLooperStartMin] = useState("");
    const [looperStartMinPos, setLooperStartMinPos] = useState(0);
    const looperStartMinRef = useRef();
    const [looperStartMinPosTrig, setLooperStartMinPosTrig] = useState(false);

    const [looperStartSec, setLooperStartSec] = useState("");
    const [looperStartSecPos, setLooperStartSecPos] = useState(0);
    const looperStartSecRef = useRef();
    const [looperStartSecPosTrig, setLooperStartSecPosTrig] = useState(false);

    const [looperEndHour, setLooperEndHour] = useState("");
    const [looperEndHourPos, setLooperEndHourPos] = useState(0);
    const looperEndHourRef = useRef();
    const [looperEndHourPosTrig, setLooperEndHourPosTrig] = useState(false);


    const [looperEndMin, setLooperEndMin] = useState("");
    const [looperEndMinPos, setLooperEndMinPos] = useState(0);
    const looperEndMinRef = useRef();
    const [looperEndMinPosTrig, setLooperEndMinPosTrig] = useState(false);

    const [looperEndSec, setLooperEndSec] = useState("");
    const [looperEndSecPos, setLooperEndSecPos] = useState(0);
    const looperEndSecRef = useRef();
    const [looperEndSecPosTrig, setLooperEndSecPosTrig] = useState(false);

    const looperLongPressEvent = useLongPress(
        () => {
            toggleLooperVisible();
            setTimeout(() => {
                if (looperVisibleRef.current) {
                    looperStartHourRef.current.focus();
                    looperStartHourRef.current.setSelectionRange(0, 0);
                }
            }, 900)

        },
        () => {
            toggleLooperActive();
        },
        {
            shouldPreventDefault: true,
            delay: 700,
        }
    );
    useEffect(() => {
        if(looperVisible){
            setPlaybackRateVisible(false)
        }
    }, [looperVisible])
    /***END LOOPER ***/

    /***Playback Rate ***/
    const playbackRateRef = useRef()
    const [playbackRate, setPlaybackRate] = useState(1.0);

    const [playbackRateVisible, setPlaybackRateVisible] = useState(false);
    const togglePlaybackRateVisible = () => {
        playbackRateVisibleRef.current = !playbackRateVisibleRef.current;
        setPlaybackRateVisible(prevState => !prevState);
    }
    const playbackRateVisibleRef = useRef(false)

    const [playbackRateActive, setPlaybackRateActive] = useState(false);
    const togglePlaybackRateActive = () => {
        playbackRateActiveRef.current = !playbackRateActiveRef.current;
        setPlaybackRateActive(prevState => !prevState);
    }
    const playbackRateActiveRef = useRef(false)
    const playbackRateLongPressEvent = useLongPress(
        () => {
            togglePlaybackRateVisible();
            setTimeout(() => {
                if (playbackRateVisibleRef.current) {
                    playbackRateRef.current.focus();
                    //playbackRateRef.current.setSelectionRange(0, 0);
                }
            }, 900)

        },
        () => {
            togglePlaybackRateActive();
        },
        {
            shouldPreventDefault: true,
            delay: 700,
        }
    );

    useEffect(() => {
        if (!!audio && playbackRateActive) {
            console.log('setting playback rate')
            audio.playbackRate = playbackRate;
        }
    }, [playbackRate])

    useEffect(() => {
        if (!!audio) {
            if (playbackRateActive) {
                audio.playbackRate = playbackRate;
            }
            else{
                audio.playbackRate = 1;
            }
        }
    }, [playbackRateActive])

    useEffect(() => {
        if (playbackRateVisible){
            setLooperVisible(false)
        }
    }, [playbackRateVisible])



    /***End Playback Rate ***/

    //const [expoPushToken, setExpoPushToken] = useState('');
    //const [notification, setNotification] = useState(false);
    //const notificationListener = useRef();
    //const responseListener = useRef();





    const resetLooper = () => {

        looperStartRef.current = 0;
        looperEndRef.current = 0;


        setLooperStartHour("");
        setLooperStartHourPos(0);
        setLooperStartHourPosTrig(false);


        setLooperStartMin("");
        setLooperStartMinPos(0);
        setLooperStartMinPosTrig(false);

        setLooperStartSec("");
        setLooperStartSecPos(0);
        setLooperStartSecPosTrig(false);

        setLooperEndHour("");
        setLooperEndHourPos(0);
        setLooperEndHourPosTrig(false);


        setLooperEndMin("");
        setLooperEndMinPos(0);
        setLooperEndMinPosTrig(false);

        setLooperEndSec("");
        setLooperEndSecPos(0);
        setLooperEndSecPosTrig(false);
    }
    const playAudio = async () => {
        audio.play();
    }

    const pauseAudio = async () => {
        audio.pause();
    }
    const playListenerCallback = () => {
        setIsPlaying(true)
    }
    const pauseListenerCallback = () => {
        setIsPlaying(false)
    }
    const loadedDataCallback = () => {
        setPlaybackInstanceDuration(audio.duration)
    }
    const timeUpdateCallback = () => {

        setLooperPlaybackPosition();
        setPlaybackInstancePosition(audio.currentTime)
    }
    const setLooperPlaybackPosition = () => {

        if (looperActiveRef.current) {
            if (audio.currentTime > looperEndRef.current && looperEndRef.current != 0) {
                audio.currentTime = looperStartRef.current;
            }
            else if (audio.currentTime < looperStartRef.current) {
                audio.currentTime = looperStartRef.current;
            }
            else if (audio.currentTime == audio.duration) {
                audio.currentTime = looperStartRef.current;
                playAudio()
            }
        }
    }
    useEffect(() => {
        if (isPlaying != player.controls.isPlaying) {
            dispatch(playerActions.setIsPlaying(isPlaying))
        }
    }, [isPlaying])

    useEffect(() => {
        if (audio != null) {
            audio.addEventListener('play', playListenerCallback)
            audio.addEventListener('pause', pauseListenerCallback)
            audio.addEventListener('loadeddata', loadedDataCallback)
            audio.addEventListener('timeupdate', timeUpdateCallback)

            if (player.controls.shouldPlay) {
                playAudio();
            }
            resetLooper();
        }
    }, [audio]);

    useEffect(() => {
        if (player.hasOwnProperty("details") && player.details.hasOwnProperty("_id") && player.details._id == null) {
            if (audioList.length > 0) {
                dispatch(playerActions.setCurrent(audioList[0]))
            }
        }
    }, [player.details, audioList])
    useEffect(() => {
        async function createAudio() {
            if (player != null && player.hasOwnProperty("details") && player.details.hasOwnProperty("_id") && player.details._id != null) {
                const url = await fileService.getFileUrl(player.details._id, auth);
                if (audio != null) {
                    pauseAudio();
                    audio.removeEventListener('play', playListenerCallback)
                    audio.removeEventListener('pause', pauseListenerCallback)
                    audio.removeEventListener('loadeddata', loadedDataCallback)
                    audio.removeEventListener('timeupdate', timeUpdateCallback)

                }
                const audioObj = new Audio(url);
                setAudio(audioObj)
                //dispatch(playerActions.setAudioRef(audioObj))
            }
        }
        createAudio()

    }, [player.details]);

    useEffect(() => {

        if (!auth.hasOwnProperty("userId")) {
            if (audio != null) {
                pauseAudio();
                audio.removeEventListener('play', playListenerCallback)
                audio.removeEventListener('pause', pauseListenerCallback)
                audio.removeEventListener('loadeddata', loadedDataCallback)
                audio.removeEventListener('timeupdate', timeUpdateCallback)

            }
            setAudio(null)
        }
    }, [auth]);

    useEffect(() => {
        let counter = 0
        let t = utilityService.formatTime(playbackInstanceDuration).replace(/0(\d+)/, "$1")
        for (let i in t) {
            if (t[i] == 0 || t[i] == '0' || t[i] == ':') {
                counter++;
            } else {
                break;
            }
        }
        setPlaybackTrim(counter)
    }, [playbackInstanceDuration])

    /* Looper Controls */
    useEffect(() => {
        if (looperStartHourPos == 2) {
            looperStartMinRef.current.focus();
            looperStartMinRef.current.setSelectionRange(0, 0);
        }
        else {
            looperStartHourRef.current.setSelectionRange(looperStartHourPos, looperStartHourPos)
        }
    }, [looperStartHourPosTrig])

    useEffect(() => {
        if (looperStartMinPos == 2) {
            looperStartSecRef.current.focus();
            looperStartSecRef.current.setSelectionRange(0, 0);
        }
        else {
            looperStartMinRef.current.setSelectionRange(looperStartMinPos, looperStartMinPos)
        }
    }, [looperStartMinPosTrig]);

    useEffect(() => {
        if (looperStartSecPos == 2) {
            looperEndHourRef.current.focus();
            looperEndHourRef.current.setSelectionRange(0, 0);
        }
        else {
            looperStartSecRef.current.setSelectionRange(looperStartSecPos, looperStartSecPos)
        }
    }, [looperStartSecPosTrig]);

    useEffect(() => {
        if (looperEndHourPos == 2) {
            looperEndMinRef.current.focus();
            looperEndMinRef.current.setSelectionRange(0, 0);
        }
        else {
            looperEndHourRef.current.setSelectionRange(looperEndHourPos, looperEndHourPos)
        }
    }, [looperEndHourPosTrig])

    useEffect(() => {
        if (looperEndMinPos == 2) {
            looperEndSecRef.current.focus();
            looperEndSecRef.current.setSelectionRange(0, 0);
        }
        else {
            looperEndMinRef.current.setSelectionRange(looperEndMinPos, looperEndMinPos)
        }
    }, [looperEndMinPosTrig]);

    useEffect(() => {
        if (looperEndSecPos == 2) {
            //looperStartHourRef.current.focus();
            looperStartHourRef.current.setSelectionRange(0, 0);
        }
        else {
            looperEndSecRef.current.setSelectionRange(looperEndSecPos, looperEndSecPos)
        }
    }, [looperEndSecPosTrig]);

    useEffect(() => {
        const looperStart = utilityService.convertLooperTime(looperStartHour, looperStartMin, looperStartSec);
        const looperEnd = utilityService.convertLooperTime(looperEndHour, looperEndMin, looperEndSec);

        looperStartRef.current = looperStart;
        looperEndRef.current = looperEnd;

    }, [looperStartHour, looperStartMin, looperStartSec, looperEndHour, looperEndMin, looperEndSec]);

    const Controls = ({ playerStatusLoaded }) => {
        if (true || playerStatusLoaded) {
            return <div style={styles.playerControls}>
                <button
                    onClick={async () => {
                        //await sound.unloadAsync();
                        for (const property in audioList) {
                            if (audioList[property]._id == player.details._id) {
                                if (parseInt(property) == 0) {
                                    dispatch(playerActions.setCurrent(audioList[audioList.length - 1], true))
                                }
                                else {
                                    dispatch(playerActions.setCurrent(audioList[parseInt(property) - 1], true))
                                }
                            }
                        }
                    }}
                    className='playerControl'
                >
                    <FontAwesomeIcon icon={faStepBackward} style={styles.playerControlIcon} />
                </button>
                <button
                    onClick={() => {
                        if (audio != null) audio.currentTime = audio.currentTime - 10
                    }}
                    className='playerControl'
                >
                    <FontAwesomeIcon icon={faUndoAlt} style={styles.playerControlIcon} />
                </button>
                {
                    isPlaying ?
                        <button
                            onClick={pauseAudio}
                            className='playerControl'
                        >
                            <FontAwesomeIcon icon={faPause} style={styles.playerControlIcon} />
                        </button>
                        : <button
                            onClick={playAudio}
                            className='playerControl'
                        >
                            <FontAwesomeIcon icon={faPlay} style={styles.playerControlIcon} />
                        </button>
                }
                <button
                    onClick={() => {
                        if (audio != null) audio.currentTime = audio.currentTime + 10
                    }}
                    className='playerControl'
                >
                    <FontAwesomeIcon icon={faRedoAlt} style={styles.playerControlIcon} />
                </button>
                <button
                    onClick={async () => {
                        //await sound.unloadAsync();
                        for (const property in audioList) {
                            if (audioList[property]._id == player.details._id) {
                                if (parseInt(property) == audioList.length - 1) {
                                    dispatch(playerActions.setCurrent(audioList[parseInt(0)], true))
                                }
                                else {
                                    dispatch(playerActions.setCurrent(audioList[parseInt(property) + 1], true))
                                }
                            }
                        }
                    }}
                    className='playerControl'
                >
                    <FontAwesomeIcon icon={faStepForward} style={styles.playerControlIcon} />
                </button>
            </div>
        }
        else {
            return <div
                style={styles.playerControls}
            >
            </div>
        }
    }
    const Toolbar = ({ playerStatusLoaded, looperActive }) => {
        if (true || playerStatusLoaded) {
            return <div className="player-toolbar">
                <button
                    {...playbackRateLongPressEvent}
                    className={`player-tool ${playbackRateActive ? 'player-tool-active' : null}`}
                >
                    <FontAwesomeIcon icon={faGauge} className={`player-tool-icon ${playbackRateActive ? 'player-tool-icon-active' : null}`} />
                </button>
                <button
                    {...looperLongPressEvent}
                    className={`player-tool ${looperActive ? 'player-tool-active' : null}`}
                >
                    <FontAwesomeIcon icon={faRepeat} className={`player-tool-icon ${looperActive ? 'player-tool-icon-active' : null}`} />
                </button>
            </div>
        }
        else {
            return <div style={styles.playerToolBar}>
            </div>
        }
    }

    return (
        <div
            style={styles.container}
            className="playerContainer"
        >
            <img
                style={{
                    width: "100%",
                    height: 10,
                    top: -5,
                    position: "relative",
                }}
                src={BottomStrip}
            />
            <Toolbar
                playerStatusLoaded={playerStatusLoaded}
                looperActive={looperActive}
            />
            <Controls
                playerStatusLoaded={playerStatusLoaded}
            />
            <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                //borderColor:"red",
                //borderWidth: 1,
                marginTop: 5,
                marginBottom: 5,
            }}>
                <div style={globalStyles.bodyText}>{player.details.fileName}</div>
            </div>
            <div style={{
                //borderColor: "orange",
                //borderWidth: 1,
                width: '100%'
            }}>

                <Slider
                    //style={{ width: '100%', height: 30, borderWidth: 1, borderColor: "blue" }}
                    value={isSeeking ? seekValue : audio != null ? audio.currentTime : 0}
                    min={0}
                    max={playbackInstanceDuration}
                    defaultValue={0}
                    onChange={value => setSeekValue(value)}
                    onBeforeChange={(value) => {
                        setSeekValue(value)
                        setIsSeeking(true);
                    }}
                    onAfterChange={(value) => {
                        //sound.setPositionAsync(value)
                        if (audio != null) audio.currentTime = value
                        setIsSeeking(false);
                    }}
                    //thumbTouchSize={{width: 40}}
                    //tapToSeek={true}
                    //thumbTintColor="rgba(2, 174, 183, .8)"
                    trackStyle={[{ backgroundColor: "rgba(2, 174, 183, 1)" }]}
                    railStyle={{ backgroundColor: "rgba(2, 174, 183, .3)" }}
                    handleStyle={{ backgroundColor: "rgba(2, 174, 183, .8)", borderColor: "rgba(2, 174, 183, .8)" }}
                //maximumTrackTintColor="rgba(2, 174, 183, .5)"
                />
            </div>
            <div style={styles.progressTimeContainer}>
                <div >
                    <div style={globalStyles.bodyText}>{utilityService.formatTime(isSeeking ? seekValue : playbackInstancePosition).replace(/0(\d+)/, "$1").slice(playbackTrim)}</div>
                </div>
                <div >
                    <div style={globalStyles.bodyText}>{utilityService.formatTime(playbackInstanceDuration).replace(/0(\d+)/, "$1").slice(playbackTrim)}</div>
                </div>
            </div>
            <div
                /*open={looperVisible}
                onClose={() => {
                    setLooperVisible(false)
                }}
                direction='top'*/
                className="tool-container"
                style={looperVisible ? window.innerWidth > 768 ? { height: window.innerHeight - theme.footerHeight } : { height: window.innerHeight - theme.mobileFooterHeight } : {}}
            >
                <div
                    className='tool-spacer'
                >
                    <div
                        className='tool-inner-container'
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ color: theme.white, height: '2em', width: '2em' }}
                            onClick={() => {
                                setLooperVisible(false)
                            }}
                        />
                        <div
                            className='tool-title'
                        >
                            Looper
                        </div>

                        <div
                            className='tool-control-group'
                        >
                            <label>Start Time</label>
                            <div
                                className='looper-controls'
                            >
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    className={`form-text-input looper-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    //autoCapitalize="none"
                                    //autoFocus={false}
                                    placeholder="hh"
                                    value={looperStartHour}
                                    ref={looperStartHourRef}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim();
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = looperStartHour;
                                        }
                                        setLooperStartHour(value);
                                        setLooperStartHourPosTrig(!looperStartHourPosTrig)

                                    }}
                                    onInput={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let newPos = regex.test(key) ? e.target.selectionStart : e.target.selectionStart - 1;
                                        if (newPos < 0) newPos = 0;
                                        setLooperStartHourPos(newPos)
                                    }}
                                />
                                :
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    className={`form-text-input looper-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    autoCapitalize="none"
                                    autoFocus={false}
                                    placeholder="mm"
                                    value={looperStartMin}
                                    ref={looperStartMinRef}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim();
                                            if (parseInt(value) > 59) {
                                                value = looperStartMin;
                                            }
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = looperStartMin;
                                        }
                                        setLooperStartMin(value);
                                        setLooperStartMinPosTrig(!looperStartMinPosTrig)

                                    }}
                                    onInput={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let newPos = e.target.selectionStart;
                                        if (!regex.test(key) || parseInt(utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim()) > 59) {
                                            newPos = e.target.selectionStart - 1;
                                            if (newPos < 0) newPos = 0;
                                        }
                                        setLooperStartMinPos(newPos);
                                    }}
                                />
                                :
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    className={`form-text-input looper-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    autoCapitalize="none"
                                    autoFocus={false}
                                    placeholder="ss"
                                    value={looperStartSec}
                                    ref={looperStartSecRef}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim();
                                            if (parseInt(value) > 59) {
                                                value = looperStartSec;
                                            }
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = looperStartSec;
                                        }
                                        setLooperStartSec(value);
                                        setLooperStartSecPosTrig(!looperStartSecPosTrig)

                                    }}
                                    onInput={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let newPos = e.target.selectionStart;
                                        if (!regex.test(key) || parseInt(utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim()) > 59) {
                                            newPos = e.target.selectionStart - 1;
                                            if (newPos < 0) newPos = 0;
                                        }
                                        setLooperStartSecPos(newPos)

                                    }}
                                />
                            </div>
                            <div
                                className='looper-button-group'
                            >
                                <button
                                    className='button looper-button'
                                    onClick={() => {
                                        const parts = utilityService.getLooperTimeParts(audio.currentTime)

                                        setLooperStartHour(parts.h);
                                        setLooperStartMin(parts.m);
                                        setLooperStartSec(parts.s);
                                    }}
                                >
                                    Current
                                </button>
                                <button
                                    className='button looper-button'
                                    onClick={() => {
                                        setLooperStartHour("");
                                        setLooperStartMin("");
                                        setLooperStartSec("");
                                    }}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                        <div
                            className='tool-control-group'
                        >
                            <label>End Time</label>
                            <div
                                className='looper-controls'
                            >
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    className={`form-text-input looper-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    //autoCapitalize="none"
                                    //autoFocus={false}
                                    placeholder="hh"
                                    value={looperEndHour}
                                    ref={looperEndHourRef}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionEnd - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionEnd).trim();
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = looperEndHour;
                                        }
                                        setLooperEndHour(value);
                                        setLooperEndHourPosTrig(!looperEndHourPosTrig)

                                    }}
                                    onInput={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionEnd - 1);
                                        let newPos = regex.test(key) ? e.target.selectionEnd : e.target.selectionEnd - 1;
                                        if (newPos < 0) newPos = 0;
                                        setLooperEndHourPos(newPos)
                                    }}
                                />
                                :
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    className={`form-text-input looper-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    autoCapitalize="none"
                                    autoFocus={false}
                                    placeholder="mm"
                                    value={looperEndMin}
                                    ref={looperEndMinRef}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionEnd - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionEnd).trim();
                                            if (parseInt(value) > 59) {
                                                value = looperEndMin;
                                            }
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = looperEndMin;
                                        }
                                        setLooperEndMin(value);
                                        setLooperEndMinPosTrig(!looperEndMinPosTrig)

                                    }}
                                    onInput={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionEnd - 1);
                                        let newPos = e.target.selectionStart;
                                        if (!regex.test(key) || parseInt(utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim()) > 59) {
                                            newPos = e.target.selectionStart - 1;
                                            if (newPos < 0) newPos = 0;
                                        }
                                        setLooperEndMinPos(newPos)

                                    }}
                                />
                                :
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    className={`form-text-input looper-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    autoCapitalize="none"
                                    autoFocus={false}
                                    placeholder="ss"
                                    value={looperEndSec}
                                    ref={looperEndSecRef}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionEnd - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionEnd).trim();
                                            if (parseInt(value) > 59) {
                                                value = looperEndSec;
                                            }
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = looperEndSec;
                                        }
                                        setLooperEndSec(value);
                                        setLooperEndSecPosTrig(!looperEndSecPosTrig)

                                    }}
                                    onInput={(e) => {
                                        const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionEnd - 1);
                                        let newPos = e.target.selectionStart;
                                        if (!regex.test(key) || parseInt(utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim()) > 59) {
                                            newPos = e.target.selectionStart - 1;
                                            if (newPos < 0) newPos = 0;
                                        }
                                        setLooperEndSecPos(newPos)

                                    }}
                                />
                            </div>
                            <div
                                className='looper-button-group'
                            >
                                <button
                                    className='button looper-button'
                                    onClick={() => {
                                        const parts = utilityService.getLooperTimeParts(audio.currentTime)
                                        setLooperEndHour(parts.h);
                                        setLooperEndMin(parts.m);
                                        setLooperEndSec(parts.s);
                                    }}
                                >
                                    Current
                                </button>
                                <button
                                    className='button looper-button'
                                    onClick={() => {
                                        setLooperEndHour("");
                                        setLooperEndMin("");
                                        setLooperEndSec("");
                                    }}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                /*open={playbackVisible}
                onClose={() => {
                    setLooperVisible(false)
                }}
                direction='top'*/
                className="tool-container"
                style={playbackRateVisible ? window.innerWidth > 768 ? { height: window.innerHeight - theme.footerHeight } : { height: window.innerHeight - theme.mobileFooterHeight } : {}}
            >
                <div
                    className='tool-spacer'
                >
                    <div
                        className='tool-inner-container'
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ color: theme.white, height: '2em', width: '2em' }}
                            onClick={() => {
                                setPlaybackRateVisible(false)
                            }}
                        />
                        <div
                            className='tool-title'
                        >
                            Playback Rate
                        </div>

                        <div
                            className='tool-control-group'
                        >
                            <label>Playback Rate</label>
                            <div
                                className='playback-controls'
                            >
                                <input
                                    type="number"
                                    inputMode="numeric"
                                    //pattern="[0-9]*"
                                    step="0.1"
                                    className={`form-text-input playback-input`}
                                    // ${shouldValidate && !inputs.username ? "form-text-input-error " : ""}
                                    //autoCapitalize="none"
                                    //autoFocus={false}
                                    placeholder="1.0"
                                    value={playbackRate}
                                    ref={playbackRateRef}
                                    min={0.2}
                                    //required pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e) => {
                                        /*const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let value = '';
                                        if (regex.test(key)) {
                                            value = utilityService.formatLooperTime(e.target.value, e.target.selectionStart).trim();
                                        }
                                        else if (e.target.selectionStart == 0 && !!e.target.value) {
                                            value = e.target.value;
                                        }
                                        else if (e.target.selectionStart == 0 && !e.target.value) {
                                            value = ""
                                        }
                                        else {
                                            value = playbackRate;
                                        }
                                        setLooperStartHour(value);
                                        setLooperStartHourPosTrig(!playbackRatePosTrig)*/
                                        console.log('onchange')
                                        if(e?.target?.value >= 0.2){
                                            setPlaybackRate(parseFloat(e.target.value));
                                        }
                                        else{
                                            setPlaybackRate(0.2);
                                        }
                                        

                                    }}
                                    onInput={(e) => {
                                        /*const regex = /[0-9]|\./;
                                        const key = e.target.value.charAt(e.target.selectionStart - 1);
                                        let newPos = regex.test(key) ? e.target.selectionStart : e.target.selectionStart - 1;
                                        if (newPos < 0) newPos = 0;
                                        setLooperStartHourPos(newPos)*/
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className='looper-button-group'
                        >
                            <FontAwesomeIcon
                                icon={faMinus}
                                style={{ color: theme.white, height: '2em', width: '2em' }}
                                onClick={() => {
                                    /*playbackRateRef.current.stepDown();
                                    console.log('should trigger change')
                                    const ev = new Event('change');
                                    playbackRateRef.current.dispatchEvent(ev);*/
                                    let tempRate = playbackRate - 0.1
                                    tempRate = Math.round(tempRate * 10) / 10
                                    if(parseFloat(tempRate) >= 0.2){
                                        
                                        setPlaybackRate(parseFloat(tempRate));
                                    }
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: theme.white, height: '2em', width: '2em', marginLeft:'15px'}}
                                onClick={() => {
                                    /*playbackRateRef.current.stepUp();
                                    const ev = new Event('change');
                                    playbackRateRef.current.dispatchEvent(ev);*/
                                    let tempRate = playbackRate + 0.1
                                    tempRate = Math.round(tempRate * 10) / 10
                                    setPlaybackRate(parseFloat(tempRate));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}