import { forgotPasswordConstants, userConstants } from "../constants";

export function forgotPassword(state = {}, action) {
  switch (action.type) {
    case forgotPasswordConstants.SET_PASSWORD_CODE:

      return {
        ...state,
        passwordCode: action.code
      };
    case forgotPasswordConstants.SET_USERNAME:
      return {
        ...state,
        username: action.username
      };
    case forgotPasswordConstants.POST_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        postPasswordCodeSuccess: true
      };
    case forgotPasswordConstants.POST_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        postPasswordCodeSuccess: false
      };
    case forgotPasswordConstants.POST_PASSWORD_SUCCESS:
      return {
        ...state,
        postPasswordSuccess: true
      };
    case forgotPasswordConstants.POST_PASSWORD_FAILURE:
      return {
        ...state,
        postPasswordSuccess: false
      };
    case forgotPasswordConstants.CLEAR_FORGOT_PASSWORD:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}