//import { Dimensions } from "react-native";

//const { height, width } = Dimensions.get("screen");
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Nunito:400', 'sans-serif']
  }
});


export const theme = {
  // Colors
  white: "#ffffff",
  black: "#000000",
  //naomiBlue: "#026C82",
  //naomiBlue: "#539dbd",
  //naomiBlue: "#0ad5ff",
  naomiBlue: "#02aeb7",
  accentColor: "#995eff",
  lightNoamiBlue: "#0ad5ff",


  //Midwest Styles
  offWhite: "#f3f3f3",
  lightGrey: "#e3e3e3",
  grey: "#cccccc",
  darkGrey: "#171717",
  red: "#e41e2e",
  lightRed: "#FAD2D5",
  teal: "#539dbd",
  green: "#219B16",
  // Form
  inputBorderColor: "#8c8c8c",
  inputPadding: 15,
  // Buttons
  btnPadding: 20,
  // Layout
  verticalSpacing: 25,
  horizontalSpacing: 12,
  font: 'Nunito',
  mobileFooterHeight: 237,
  footerHeight: 182
  //height,
  //width,
}