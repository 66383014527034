import { combineReducers } from "redux";
import { alert } from './alertReducer';
import { authentication } from './authReducer';
import { view } from './viewReducer';
import { user } from "./userReducer";
import { audio } from "./audioReducer";
import { player } from "./playerReducer";
import { notes } from "./notesReducer";
import { persistReducer } from "redux-persist";
import { forgotPassword } from "./forgotPasswordReducer";

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const authenticationPersistConfig = {
  key: "authentication",
  storage
};
const userPersistConfig = {
  key: "user",
  storage
};
const forgotPasswordPersistConfig = {
  key: "forgotPassword",
  storage
};

const rootReducer = combineReducers({
  alert,
  view,
  audio,
  notes,
  user: persistReducer(userPersistConfig, user),
  authentication: persistReducer(authenticationPersistConfig, authentication),
  player,
  forgotPassword: persistReducer(forgotPasswordPersistConfig, forgotPassword)
});

export default rootReducer;