
const dateToHHMMSS = (seconds) => {
    seconds = Math.ceil(seconds);
    var date = new Date(0);
    date = new Date( date.getTime() + (date.getTimezoneOffset() * 60000));
    date.setSeconds(seconds); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    return timeString;
}


const formatTime = (time) => {

    if (!isNaN(time)) {
        //console.log(new Date(time).toISOString())
        //console.log('formatTime')
        //console.log(new Date(time).toISOString().substr(11, 8))
        return (
            //Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
            new Date(time * 1000).toISOString().substr(11, 8)
        );
    }
}
const formatLooperTime = (time, position) => {
    //if (position != 0) {

        //const replacement = time.charAt(position - 1);
        time = time.substring(0, position) + time.substring(position + 1);

        time = time.substring(0, 2);

    //}
    return time;
}
const convertLooperTime = (h, m, s) =>{
    h = !!h ? h : 0;
    m = !!m ? m : 0;
    s = !!s ? s : 0;


    return parseInt(h) * 3600 + parseInt(m) * 60 + parseInt(s);
}

const getLooperTimeParts = (time) =>{
    const timeString = new Date(time * 1000).toISOString().substr(11, 8);
    const timeArray = timeString.split(":").reverse();

    return {
        h: timeArray.length > 2 ? timeArray[2] : "",
        m: timeArray.length > 1 ? timeArray[1] : "",
        s: timeArray.length > 0 ? timeArray[0] : ""
    }

}
/*const formatLooperTime = (time, position) => {
    if (position != 0) {

        const replacement = time.charAt(position - 1);
        const regex = /[0-9]|\./;
        if( regex.test(replacement) ) {
            time = time.substring(0, position) + time.substring(position + 1);
            
        }
        else{
            time = time.replace(/[^0-9]/g, '');
            time = time.substring(0, position) + time.substring(position);
        }
        time= time.substring(0, 2);

    }
    return time;
}

*/

/*const screenWidth = () => {
    const dim = Dimensions.get('screen');
    return dim.width
}

const screenHeight = () => {
    const dim = Dimensions.get('screen');
    return dim.height
}
*/

export const utilityService = {
    dateToHHMMSS,
    formatTime,
    formatLooperTime,
    convertLooperTime,
    getLooperTimeParts
    //screenWidth,
    //screenHeight,
};