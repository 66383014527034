import axios from "axios";
//import oauth from "axios-oauth-client";
import { api } from "../utilities/api";
import { userActions } from "../actions/userActions";
import { store } from "../utilities/store";

const getAll = async (type, auth) => {
  const apiDomain = api.url;
  return await axios.get(`${apiDomain}/files${!!type ? '?type=' + type : ''}`
    , { "headers": { "Authorization": "Bearer " + auth.token, "Content-Type": "application/json", "X-API-KEY": api.apiKey } })
    .then((response) => response)
    .catch((error) => {
      console.log(error)
      const dispatch = store.dispatch;
      if (error?.response?.status == 401) dispatch(userActions.logout());
    });
}

const getFileUrl = async (fileId, auth) => {
  const apiDomain = api.url;
  return await axios.get(`${apiDomain}/uploads/${fileId}`
    , { "headers": { "Authorization": "Bearer " + auth.token, "Content-Type": "application/json", "X-API-KEY": api.apiKey } })
    .then((response) => response.data.uri)
    .catch((error) => {
      const dispatch = store.dispatch;
      if (error?.response?.status == 401) dispatch(userActions.logout());
      console.log(error);
    });
}
const getApkRedirect = async (auth) => {
  const apiDomain = api.url;
  return await axios.get(apiDomain + "/android", { "headers": { "Authorization": auth.token, "X-API-KEY": api.apiKey } })
    .then((response) => response.data)
    .catch((error) => {
      const dispatch = store.dispatch;
      if (error.response.status == 401) dispatch(userActions.logout());
      console.log(error);
    });
}

const getSignedUploadUrl = async (fileName, contentType, auth) => {
  const apiDomain = api.url;
  return await axios.get(apiDomain + "/uploads/url/" + encodeURI(fileName) + '?contenttype=' + contentType
    , { "headers": { "Authorization": "Bearer " + auth.token, "Content-Type": "application/json", "X-API-KEY": api.apiKey } })
    .then((response) => response.data.presignedUrl)
    .catch((error) => {
      const dispatch = store.dispatch;
      if (error.response.status == 401) dispatch(userActions.logout());
      console.log(error);
    });
}

const postFile = async (fileName, type, time, size, date, auth) => {
  const apiDomain = api.url;

  return await axios.post(apiDomain + "/files/", { "fileName": fileName, "type": type, "time": time, "size": size, "practiceDate": date }, {
    "headers": { "Authorization": auth.token, "X-API-KEY": api.apiKey }
  }).then((response) => response
  ).catch((error) => {
    const dispatch = store.dispatch;
    console.log(error)
    if (error.response.status == 401) dispatch(userActions.logout());
    return {};
  });
}

export const fileService = {
  getAll,
  getFileUrl,
  getApkRedirect,
  getSignedUploadUrl,
  postFile
};

