import { playerConstants, userConstants } from "../constants";

const initialState={
    details:{
        "_id": null,
        "fileName": "No Track is selected",
        "path": "",
        "time": 0,
        "slug": null
    },
    controls:{
        playbackInstancePosition: null,
        playbackInstanceDuration: null,
        shouldPlay: false,
        isPlaying: false,
        isBuffering: false,
        isLoading: true,
        volume: 1.0,
        rate: 1.0,
    },
    audioRef: null
}

export function player(state = initialState, action) {
  switch (action.type) { 
    case playerConstants.SET_CURRENT:
        
        return {
            ...state,
            details: {
                ...action.details,
            },
            controls: {
                ...state.controls,
                "shouldPlay": action.shouldPlay,
            }

        }
    case playerConstants.SET_IS_PLAYING:
        return {
            ...state,
            controls: {
                ...state.controls,
                "isPlaying": action.isPlaying
            }
        }  
    case playerConstants.SET_AUDIO_REF:
        return {
            ...state,
            audioRef: action.audioRef
        }
    case userConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
}