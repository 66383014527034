import { audioConstants } from "../constants";
import { fileService } from "../services/fileService";
import { alertActions } from "./alertActions";

export const audioActions = {
    getAll
}

function getAll(filter, auth) {
    return (dispatch) => {
      //if(JSON.stringify(auth) === "{}") return false;
      //fileService.getAll('audio', auth)
      dispatch(request())
      fileService.getAll(filter, auth)
      .then((audioList) => {
        if(!!audioList){
          const list = Object.keys(audioList).length > 0 ? audioList.data.fileList.reverse() : audioList.data.fileList;
          //dispatch(alertClear())
          dispatch(success(list));
        }
        else{
          dispatch(failure());
        }

      },
      (error) => {
        console.log(error);
        dispatch(alertActions.error('Something went wrong'));
        dispatch(failure());
      });

      /*    return {
      "fileList": [
        {
          "_id": "60d6a3c5edcfd0013e5fd21d",
          "fileName": "12-1-2019_2.mp3",
          "path": "/wp-content/uploads/2019/12/12-1-2019_2.mp3",
          "date": "12/8/2019",
          "size": 206211871,
          "type": null,
          "practiceDate": "12/8/2019",
          "time": 5155.272
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd21e",
          "fileName": "12_22_2019.mp3",
          "path": "/wp-content/uploads/2020/03/12_22_2019.mp3",
          "date": "3/3/2020",
          "size": 56180191,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 1404.48
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd21f",
          "fileName": "12_29_2019_1.mp3",
          "path": "/wp-content/uploads/2020/03/12_29_2019_1.mp3",
          "date": "3/3/2020",
          "size": 178512991,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 4462.8
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd220",
          "fileName": "12_29_2019_2.mp3",
          "path": "/wp-content/uploads/2020/03/12_29_2019_2.mp3",
          "date": "3/3/2020",
          "size": 16463071,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 411.552
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd221",
          "fileName": "12_29_2019_3.mp3",
          "path": "/wp-content/uploads/2020/03/12_29_2019_3.mp3",
          "date": "3/3/2020",
          "size": 76295071,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 1907.352
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd222",
          "fileName": "01_05_2019.mp3",
          "path": "/wp-content/uploads/2020/03/01_05_2019.mp3",
          "date": "3/3/2020",
          "size": 254884831,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 6372.096
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd223",
          "fileName": "01_25_2019_1.mp3",
          "path": "/wp-content/uploads/2020/03/01_25_2019_1.mp3",
          "date": "3/3/2020",
          "size": 55088671,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 1377.192
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd224",
          "fileName": "01_19_2019.mp3",
          "path": "/wp-content/uploads/2020/03/01_19_2019.mp3",
          "date": "3/3/2020",
          "size": 308059231,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 7701.456
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd225",
          "fileName": "01_26_2019_1.mp3",
          "path": "/wp-content/uploads/2020/03/01_26_2019_1.mp3",
          "date": "3/3/2020",
          "size": 127992031,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 3199.776
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd226",
          "fileName": "01_26_2019_2.mp3",
          "path": "/wp-content/uploads/2020/03/01_26_2019_2.mp3",
          "date": "3/3/2020",
          "size": 194438431,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 4860.936
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd227",
          "fileName": "02_02_2019_1.mp3",
          "path": "/wp-content/uploads/2020/03/02_02_2019_1.mp3",
          "date": "3/3/2020",
          "size": 308880991,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 7722
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd228",
          "fileName": "02_02_2019_2.mp3",
          "path": "/wp-content/uploads/2020/03/02_02_2019_2.mp3",
          "date": "3/3/2020",
          "size": 121222111,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 3030.528
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd229",
          "fileName": "02_16_2019.mp3",
          "path": "/wp-content/uploads/2020/03/02_16_2019.mp3",
          "date": "3/3/2020",
          "size": 14820511,
          "type": null,
          "practiceDate": "3/3/2020",
          "time": 370.488
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd22a",
          "fileName": "02_23_2019.mp3",
          "path": "/wp-content/uploads/2020/03/02_23_2019.mp3",
          "date": "3/4/2020",
          "size": 341853151,
          "type": null,
          "practiceDate": "3/4/2020",
          "time": 8546.304
        },
        {
          "_id": "60d6a3c5edcfd0013e5fd22b",
          "fileName": "03_01_2019.mp3",
          "path": "/wp-content/uploads/2020/03/03_01_2019.mp3",
          "date": "3/7/2020",
          "size": 489800671,
          "type": null,
          "practiceDate": "3/7/2020",
          "time": 12244.992
        }
      ],
      "success": true
    }*/
    };
  
    function success(audioFileList) {
      return { type: audioConstants.GETALL_SUCCESS, audioFileList };
    }
    function failure() {
      return { type: audioConstants.GETALL_FAILURE };
    }

    function request(){
      return { type: audioConstants.GETALL_REQUEST };
    }
  }

