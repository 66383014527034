import { audioConstants, userConstants } from "../constants"

const initialState = {
    list: {}
}
export function audio(state = initialState, action) {

    switch (action.type) {
        case audioConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case audioConstants.GETALL_SUCCESS:
            return {
                ...state,
                list: action.audioFileList,
                loading: false
            }
        case audioConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false
            }
        case audioConstants.CLEAR_AUDIO:
            return {}
        case userConstants.LOGOUT:
            return initialState;
        default:
            return state
    }
}