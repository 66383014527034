import React from "react";

import AudioListItem from "./AudioListItem";

export default ( item, idx) => {

    return (
        <AudioListItem
            key={item._id}
            _id={item._id}
            date={item.date}
            fileName={item.fileName}
            path={item.path}
            practiceDate={item.practiceDate}
            size={item.size}
            time={item.time}
            type={item.type}
        />
    );
};