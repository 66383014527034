import React from "react";
//import './Home.scss';

import {
  Outlet
} from "react-router-dom";
import Footer from '../Footer/Footer';
import Navigation from '../Navigation/Navigation';
import './PageLayout.scss';
import Header from '../Header/Header';

export default function PageLayout() {

  return (
    <div style={{
      height: '100%'
    }}>
      <Header />
      <div
        className="page-container"
      >
        <Outlet />
      </div>
      <Footer />
      <Navigation />
    </div>
  );
}
