import { alertConstants, userConstants } from "../constants";

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.REQUEST:
      return {
        loading: true
      };

    case alertConstants.SUCCESS:
      return {
        type: "success",
        message: action.message,
      };

    case alertConstants.ERROR:
      //console.log('alert reducer error')
      //console.log(action.message);
      return {
        type: "error",
        message: action.message,
      };
    
    case alertConstants.RESET:
      return {
        reset: true
      }

    case alertConstants.CLEAR:
      return {};
    
    case userConstants.LOGOUT:
      return {};
      
    default:
      return state;
  }
}