import React, { useEffect } from "react";

import AudioList from "../../components/Lists/Audio/AudioList";
import { useSelector } from "react-redux";
import Hourglass from '../../assets/images/Hourglass.png';
import { theme } from "../../assets/theme";
import { viewActions } from "../../actions";
import FlatList from 'flatlist-react';
import './Audio.scss';
import ActivityIndicator from 'react-activity-indicator'
import 'react-activity-indicator/src/activityindicator.css'

export default function Audio() {
    const loading = useSelector((state) => state.audio.loading);
    const audioList = useSelector((state) => state.audio.list);

    useEffect(() => {
        viewActions.current('Home')
    })
    /*const AudioList = () => {
        if (!!loading) {
            return (
                <ActivityIndicator activeColor={theme.naomiBlue} style={{ alignSelf: 'center' }} />
            );
        }
        else {
            return (

                <FlatList
                    renderWhenEmpty={() => <div className="empty-audio-list">No audio files found.</div>}
                    list={audioList}
                    renderItem={AudioList}
                    className=""
                    renderOnScroll
                />
            );
        }
    }*/
    return (
        <div
        >
            <div
                className="container"
            >
                <div
                    className="audio-header"
                >
                    <div
                        className="audio-header-title"
                    >
                        Title
                    </div>
                    <img src={Hourglass} className="audio-header-icon" />
                </div>

                {
                    !!loading
                        ? <div
                            className="vertical-center horizontal-center audio-loader"
                        >
                            <ActivityIndicator activeColor={theme.naomiBlue} style={{ alignSelf: 'center', marginRight: '20px' }} />
                        </div>
                        : <div
                            className="audio-list"
                        >

                            <FlatList
                                renderWhenEmpty={() => <div className="empty-audio-list">No audio files found.</div>}
                                list={audioList}
                                renderItem={AudioList}
                                /*style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    overflow: "hidden",
                                    marginBottom: 50,

                                }}*/
                                className=""
                                renderOnScroll
                            />
                        </div>
                }
            </div>
        </div>
    );
}
