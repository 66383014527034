import React, { useEffect, useState } from 'react';
import "./PasswordInput.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons'

export default function PasswordInput({ onChange, label, value, placeholder, showError, errorMessage, enabled, theme = 'light'}) {

    //const [password, setPassword] = useState("");
    const [securePassword, setSecurePassword] = useState(true);
    const toggleSecurePassword = () => setSecurePassword(prevState => !prevState);

    const ValidationError = ({ showValidationMessage, validationMessage }) => {
        if (!!showValidationMessage) {
            const message = !!validationMessage ? validationMessage : "Password is required"
            return <div className="form-validation-error">{validationMessage}</div>
        }
        else {
            return null
        }
    }

    if (!!enabled){
        return (
            <>
                <div className="form-group">
                    <ValidationError
                        showValidationMessage={showError}
                        validationMessage={errorMessage}
                    />
                    <div
                        className="form-password-input-container"
                    >
                        <input
                            type={securePassword ? "password" : "text"}
                            className={`form-password-input ${showError ? "form-text-input-error " : ""} ${theme == 'dark' ? 'dark-input' : 'light-input'}`}
                            autoCapitalize="none"
                            autoFocus={false}
                            placeholder={!!placeholder ? placeholder : !!label ? label : "Enter your password"}
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                        />
                        <button
                            className="view-password"
                            onClick={() => {
                                toggleSecurePassword();
                            }}
                        >
                            <FontAwesomeIcon icon={securePassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <div className={`form-label ${theme == 'dark' ? 'dark-label' : 'light-input'}`}>{!!label ? label : 'Enter Password'}</div>
                </div>
            </>
    
        );
    }
    else{
        return <></>
    }

}
