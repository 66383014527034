import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions";
//import { FontAwesome } from "@expo/vector-icons";
import { theme } from "../../assets/theme"
//import ProfilePic from "../../assets/images/ProfilePic.png"
import ProfilePic from "../../assets/images/ProfileWhite.svg"
import Download from "../../assets/images/Download.png"
import { saveAs } from "file-saver";
import { fileService } from '../../services';
import "./Profile.scss"
import {
  useNavigate
} from "react-router-dom";
//import ResetPassword from './ResetPassword/ResetPassword';
export default function Profile() {

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.authentication);
  //const [touchIdEnabled, setTouchIdEnabled] = useState(user.touchIdEnabled);
  //const toggleSwitch = () => setTouchIdEnabled(previousState => !previousState);
  //const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const view = useSelector((state) => state.view);
  //const network = useSelector((state) => state.network);

  const DownloadApk = () => {
    return <div >
      <button
        onClick={downloadApk}
        style={{
          marginBottom: theme.verticalSpacing * 2,
          backgroundColor: 'transparent',
          borderStyle: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginTop: theme.verticalSpacing * 2,
        }}
      >
        <img
          style={{ width: 80, height: 80, resizeMode: "contain", alignSelf: "flex-start" }}
          src={Download}
        />
        <div style={{
          color: theme.white,
          fontStyle: 'Nunito'
        }}>Download the Android app</div>
      </button>
    </div>
  }
  const downloadApk = () => {

    fileService.getApkRedirect(auth)
      .then((data) => {
        if (data.success) {
          saveAs(
            data.uri,
            "android.apk"
          )
        }
      },
        (error) => {
          console.log(error)
        });
  }

  /*useEffect(() => {
      dispatch(userActions.modify({ ...user, touchIdEnabled: touchIdEnabled }));
    }, [touchIdEnabled]);
  */
  return (

    <div
      className='profile-container'
    >
      <div className="profile-form">
      <div
        className='title profile-title'
      >
        Profile
      </div>
        <img
          className='profile-pic'
          src={ProfilePic}
        />
        <div className='form-group'>
          <div
            className='form-text-input'
          >{user.username}</div>
          <div className='form-label profile-label'>Username</div>
        </div>
        <div className='form-group'>
          <div
            className='form-text-input'
          >
            {user.email}
          </div>
          <div className='form-label profile-label'>Email</div>
        </div>
        <button

          onClick={() => {
            dispatch(userActions.logout());
            //navigate("/login");
            //window.location.reload();
          }}
          className="button btnText btn-profile-logout"
        >
          Log Out
        </button>
        <div className="reset-password" onClick={() => {
        navigate("/account/reset-password");
      }}>
        Reset Password
      </div>
      </div>
    </div>
  );
}
