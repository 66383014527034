
const current = () => {
  /*const routes = useNavigationState(state => state.routes);
  const subRoute = typeof routes != "undefined" && typeof routes[routes.length -1] != "undefined" && routes[routes.length -1].hasOwnProperty("state") && routes[routes.length -1].state != null && routes[routes.length -1].state.hasOwnProperty("routes") ? routes[routes.length -1].state.routes : false;
  const rootView = subRoute ? false : routes[routes.length -1].name;
  const subView = subRoute ? subRoute[subRoute.length - 1].name : false;
  const currentView = subRoute ? subView : rootView;*/
  const currentView = '';
  return currentView;
}

export const viewService = {
  current,
};