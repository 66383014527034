import { viewConstants } from "../constants";

export const viewActions = {
  current,
  clear,
  setIsMobile,
  toggleNavigation
};

function current(currentView) {
  return { type: viewConstants.CURRENT, currentView };
}

function setIsMobile(isMobile) {
  return { type: viewConstants.SET_IS_MOBILE, isMobile };
}

function toggleNavigation() {
  return { type: viewConstants.TOGGLE_NAVIGATION };
}

function clear() {
  return { type: viewConstants.CLEAR };
}