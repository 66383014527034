import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { forgotPasswordActions } from "../../../actions";
import "./EnterCode.scss"
import {
  useNavigate
} from "react-router-dom";
export default function EnterCode() {
  //const [touchIdEnabled, setTouchIdEnabled] = useState(user.touchIdEnabled);
  //const toggleSwitch = () => setTouchIdEnabled(previousState => !previousState);
  //const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  // const [shouldValidate, setShouldValidate] = useState(false);
  //const [isValid, setIsValid] = useState(false);
  //const [submitted, setSubmitted] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!!code) {
      dispatch(forgotPasswordActions.setPasswordCode(code));
      navigate("/forgot-password/reset-password");
    }
    else {
      setShowValidationMessage(true)
    }
  }
  

  /* useEffect(() => {
     submitted && setShouldValidate(true);
   }, [submitted])
 
   useEffect(() => {
 
       shouldValidate && setIsValid(!!code)
       shouldValidate && validate()
   }, [shouldValidate])
 
   const validate = () => {

 
     if (submitted) {
       if (isValid) {
         setIsValid(true)
       }
       else {
         setShowValidationMessage(true)
       }
     }
 
   }
 
   useEffect(() =>{
     navigate("/forgot-password/enter-code");
   },[])
 
 */
  return (

    <div
    >
      <div className="form">
        <div 
          className="form-group"
        >
          <div className='paragraph center vertical-spacing'>
            A code has been sent to your email. Enter the code below.
          </div>
          {showValidationMessage ? <div className="form-validation-error">Code is required</div> : null}
          <input
            type="text"
            className={`form-text-input ${showValidationMessage ? "form-text-input-error " : ""}`}
            autoCapitalize="none"
            autoFocus={false}
            placeholder="Enter your code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <div className="form-label">Enter code</div>
        </div>
        <div
          className="form-group"
        >
          <button
            onClick={handleSubmit}
            className="button"
          >
            Submit
          </button>
          <div className="forgot-password" onClick={() => {
            dispatch(forgotPasswordActions.clear());
            navigate("/login");
          }}>
            Back to Login
          </div>
        </div>
      </div>
    </div>
  );
}
