import { userConstants } from "../constants";

export function user(state = {}, action) {
  switch (action.type) {

    case userConstants.GETUSER_SUCCESS:
      return {
        ...state,
        userId: action.user.userId,
        username: action.user.username,
        email: action.user.email,
        role: action.user.role,
        name: action.user.name,
        firstName: action.user.firstName,
        lastName: action.user.lastName
      };
    case userConstants.GETUSER_FAILURE:
      return {};
    case userConstants.MODIFY_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case userConstants.MODIFY_FAILURE:
      return {};
      
    case userConstants.LOGOUT:
        return {};

    case userConstants.GET_TENANT_SUCCESS:
        return {
          ...state,
          tenant: action.tenant
        };
      
    default:
      return state;
  }
}