//import { StatusBar } from "react-native";
import { theme } from "../../assets/theme";
 

const styles = {

navigationIcon: {
    color: theme.naomiBlue,
    height: 30,
    fontSize: 30,
    lineHeight: 30,
    },
  // Tabs
  tabs: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingLeft: 15,
    paddingRight: 15,
    zIndex:997
    //borderColor:"red",
    //borderWidth: 1,
    //width: "100%",

    //borderTopLeftRadius: 150,
    //borderTopRightRadius: 150,
    //overflow: "hidden",
    //height: 150,


    //backgroundColor: theme.black,
  },
  tabsLandscape: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    //paddingLeft: 15,
    //paddingRight: 15,
    position: 'absolute',
    right: -100,
    width: 100,
    top: null,
    bottom: 0,
    borderColor:"green",
    borderWidth: 1,
    //height: utilityService.screenHeight(),
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    //zIndex: 99
  },
  tabItem: {
    flexGrow: 1,
    //padding: 25,
    //borderWidth: 1,
    //borderColor: "red",
    backgroundColor: 'rgba(0,0,0,0)',
    borderStyle: 'none',
  },
  tabItemLandscape: {
    flexGrow: 1,
    //padding: 25,
    //borderWidth: 1,
    //borderColor: "red",
    justifyContent: 'center'
  },
  tabItemActive: {

  },
  tabItemText: {
    //width: "100%",
    textAlign: "center",
    color: theme.naomiBlue,
    textTransform: "uppercase",
    fontSize: 12,
    //lineHeight: 19,
    //fontFamily: "SourceSansPro_900Black",
  },
  tabItemHighlight: {
    width: "60%",
    alignSelf: "center",
    height: 3,
    backgroundColor: theme.naomiBlue,
    margin: 'auto',
    maxWidth: 150
    //marginTop: 20,
    //marginBottom: -22,
  },
};

export default styles;