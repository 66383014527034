import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
import ResetPasswordForm from '../../../components/ResetPassword/ResetPassword'
import "./ResetPassword.scss"
import {
  useNavigate
} from "react-router-dom";
export default function ResetPassword() {

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const user = useSelector((state) => state.user);
  let passwordRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if ( !!auth?.token && !!user?.userId && auth?.challenge?.challengeName != 'NEW_PASSWORD_REQUIRED' ) { 
      navigate("/");
    }
  }, [auth, user]);

  return (
    <>
      <ResetPasswordForm 
        onSubmit={() => dispatch(userActions.postPermanentPassword(auth?.challenge, passwordRef.current))}
        passwordRef={passwordRef}
        
      />
      <div 
        className="forgot-password" 
        onClick={() => {
          //dispatch(forgotPasswordActions.clear());
          navigate("/login");
        }}>
        Back to Login
      </div>
    </>
  );
}
