import axios from "axios";
//import oauth from "axios-oauth-client";
import { api } from "../utilities/api";
import { store } from "../utilities/store";
import { userActions } from "../actions/userActions";


const putNotes = async (_id, note, auth, fileId, fileName, type, tags = null, startTime = null, endTime = null) => {


  const apiDomain = api.url;
  return await axios.put(apiDomain + "/notes/" + _id, { "note": note, "type": type, "fileId": fileId, "fileName": fileName, "tags": tags, "startTime": startTime, "endTime": endTime }, {
    "headers": { "Authorization": auth.token, "X-API-KEY": api.apiKey }
  }).then((response) => response
  ).catch((error) => {
    console.log(error);
    const dispatch = store.dispatch;
    if (error.response.status == 401) dispatch(userActions.logout());
    return {};
  });
}
const postNotes = async (_id, note, auth, fileId, fileName, type, tags = null, startTime = null, endTime = null) => {


  const apiDomain = api.url;
  return await axios.post(apiDomain + "/notes", { "note": note, "type": type, "fileId": fileId, "fileName": fileName, "tags": tags, "startTime": startTime, "endTime": endTime }, {
    "headers": { "Authorization": auth.token, "X-API-KEY": api.apiKey }
  }).then((response) => response
  ).catch((error) => {
    console.log(error);
    const dispatch = store.dispatch;
    if (error.response.status == 401) dispatch(userActions.logout());
    return {};
  });
}

const getNotes = async (auth, fileId) => {

  const apiDomain = api.url;

  return await axios.get(`${apiDomain}/notes${!!fileId ? '?fileId=' + fileId : ''}`, {
    "headers": { "Authorization": auth.token, "X-API-KEY": api.apiKey }
  }).then((response) => response
  ).catch((error) => {
    console.log(error);
    const dispatch = store.dispatch;
    if (error.response.status == 401) dispatch(userActions.logout());
    return {};
  });
}

const getFileIdsFromNotes = async (auth) => {

  const apiDomain = api.url;
  return await axios.get(apiDomain + "/notes/fileids", {
    "headers": { "Authorization": auth.token, "X-API-KEY": api.apiKey }
  }).then((response) => response
  ).catch((error) => {
    console.log(error);
    const dispatch = store.dispatch;
    if (error.response.status == 401) dispatch(userActions.logout());
    return {};
  });
}

export const notesService = {
  getNotes,
  putNotes,
  postNotes,
  getFileIdsFromNotes
};