import React from "react";
import Player from "../Player/Player";
import MobileNavigation from '../MobileNavigation/MobileNavigation'
import './Footer.scss'
import GreyTextureBackground from '../../assets/images/GreyTextureBackground.png'

const Footer = () => {
  return (
      <div 
       style={{
          backgroundImage: `url(${GreyTextureBackground})`,  
        }}
        className="footer-container"
      >

          <div
            style={{
              backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.3))',
              position: 'relative'
            }}
          >
              <Player />
              <MobileNavigation />
              </div>
      </div>
  )
}

export default Footer;