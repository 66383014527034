import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewService } from "../../services";
import { viewActions } from "../../actions";
import BottomArch from '../../assets/images/BottomArch.png';

//import globalStyles from "../../assets/global.style";
import styles from "./MobileNavigation.style";

import HomeIcon from '../../assets/images/Home.png';
import HomeInactiveIcon from '../../assets/images/HomeInactive.png';

import UploadIcon from '../../assets/images/Upload.png';
import UploadInactiveIcon from '../../assets/images/UploadInactive.png';

//import GearIcon from '../../assets/images/Gear.svg';
import ProfileIcon from '../../assets/images/Profile.png';
import ProfileInactiveIcon from '../../assets/images/ProfileInactive.png';

//import RefreshIcon from '../../assets/images/Refresh.svg';
import './MobileNavigation.scss'
import {
    useNavigate,
    useLocation
} from "react-router-dom";

export default function MobileNavigation() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentView = viewService.current();

    useEffect(() => {
        //if (auth.hasOwnProperty("user")) utilityService.debounce(dispatch(inspectionActions.getAll(JSON.stringify({ inspector_ids: parseInt(auth.user.user_id) }), "1", auth)), 1000);
    }, []);
    /*useEffect(() => {
        //console.log(orientation.current)
        if (auth.hasOwnProperty("userId")){
            dispatch(alertActions.request());
            dispatch(audioActions.getAll(auth));
            dispatch(notesActions.getFileIdsFromNotes(auth));
        } 
      }, []);*/

    useEffect(() => {
        if (currentView !== undefined) dispatch(viewActions.current(currentView));
    }, [currentView]);


    return (
        <div
            style={{
                //borderStyle: 'solid',
                //borderColor: "blue",
                //borderWidth: 1,
                height: 70,
                backgroundImage: `url(${BottomArch})`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                flexDirection: "column",
                justifyContent: "flex-end",
                //alignItems: "space-between",
            }}
            className="mobileNavContainer"
        >

            <div style={styles.tabs}>
                <button
                    style={
                        styles.tabItem
                        //view.current === "Home" ? styles.tabItemActive : null
                    }
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <div
                        style={{
                            //opacity: location.pathname == "/audio" || location.pathname == "/" || location.pathname.includes('notes') === "Home" ? 1 : 0.6,
                            //zIndex: 10,
                            position: 'relative'
                        }}
                        //className={`mobile-nav-icon ${location.pathname == "/audio" || location.pathname == "/" || location.pathname.includes('notes') === "Home" ? "mobile-nav-icon-opaque" : ""}`}
                        className={`mobile-nav-icon`}
                    >
                        {
                            location.pathname == "/audio" || location.pathname == "/" || location.pathname.includes('notes') === "Home"
                                ? <img src={HomeIcon} style={styles.navigationIcon} />
                                : <img src={HomeInactiveIcon} style={styles.navigationIcon} />
                        }

                        <div style={styles.tabItemText}>Home</div>
                        {<div style={location.pathname == "/audio" || location.pathname == "/" || location.pathname.includes('notes') ? styles.tabItemHighlight : { backgroundColor: "transparent" }}></div>}
                    </div>
                </button>
                <button
                    style={
                        styles.tabItem
                        //view.current === "Home" ? styles.tabItemActive : null
                    }
                    onClick={() => {
                        navigate("/upload");
                    }}
                >
                    <div
                        style={{
                            //opacity: location.pathname == "/upload" ? 1 : 0.6,
                            //zIndex: 10,
                            position: 'relative'
                        }}
                        //className={`mobile-nav-icon ${location.pathname == "/upload" ? "mobile-nav-icon-opaque" : ""}`}
                        className={`mobile-nav-icon`}

                    >
                        {location.pathname == "/upload"
                            ? <img src={UploadIcon} style={styles.navigationIcon} />
                            : <img src={UploadInactiveIcon} style={styles.navigationIcon} />
                        }
                        
                        <div style={styles.tabItemText}>Upload</div>
                        {<div style={location.pathname == "/upload" ? styles.tabItemHighlight : { backgroundColor: "transparent" }}></div>}
                    </div>
                </button>
                <button
                    style={
                        styles.tabItem
                        //, view.current === "profile" ? styles.tabItemActive : null
                    }
                    onClick={() => {
                        //navigation.navigate("Profile") 
                        navigate("/account/profile");
                    }}
                >
                    <div
                        style={{
                            //opacity: location.pathname == "/account/profile" ? 1 : 0.6,
                            //zIndex: 10,
                            position: 'relative'
                        }}
                        //className={`mobile-nav-icon ${location.pathname == "/account/profile" ? "mobile-nav-icon-opaque" : ""}`}
                        className={`mobile-nav-icon `}

                    >
                        {location.pathname == "/account/profile"
                            ? <img src={ProfileIcon} style={styles.navigationIcon} />
                            : <img src={ProfileInactiveIcon} style={styles.navigationIcon} />
                        }
                        <div style={styles.tabItemText}>Profile</div>
                        {<div style={location.pathname == "/account/profile" ? styles.tabItemHighlight : { backgroundColor: "transparent" }}></div>}
                    </div>
                </button>
                {/*<button
                    style={[styles.tabItem, view.current === "profile" ? styles.tabItemActive : null]}
                    div={() => { navigation.navigate("Profile") }}
                >
                    <RefreshIcon style={styles.navigationIcon} />
                    <Text style={styles.tabItemText}>Refresh</Text>
                </button>*/}
            </div>
        </div>
    );
}
