import React from "react";

import ApolloLogoSplash from "../../assets/images/ApolloLogoSplash.png";

const Loading = () => {
    return (
        <div
          style={{
            backgroundImage: `url(${ApolloLogoSplash})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
            backgroundColor: "#02aeb7",
          }}
        >

        </div>
    )
}


export default Loading;