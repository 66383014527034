import axios from "axios";
//import oauth from "axios-oauth-client";
import { api } from "../utilities/api";
import { store } from "../utilities/store";
import { userActions } from "../actions/userActions";

const login = async (username, password, touchAuth = false) => {
  const authParams = JSON.stringify({
    "username": username,
    "password": password,
  });

  const apiDomain = api.url;

  return await axios.post(apiDomain + "/authenticate", authParams, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-API-KEY": api.apiKey
    },
    //httpsAgent 
  }).then((auth) => auth.data
  ).catch((error) => {
    console.log(error);
    return {};
  });
}

const getUserDetails = async (token) => {
  const apiDomain = api.url;
  return await axios.get(apiDomain + "/users/user", {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "X-API-KEY": api.apiKey
    },
    //httpsAgent 
  }).then((user) => user)
    .catch((error) => {
      console.log(error);
      const dispatch = store.dispatch;
      if (error.response.status == 401) dispatch(userActions.logout());
      return {};
    });
}

const getUserTenant = async (token) => {
  const apiDomain = api.url;
  return await axios.get(apiDomain + "/users/user/tenant/", {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "X-API-KEY": api.apiKey
    },
    //httpsAgent 
  }).then((tenant) => tenant)
    .catch((error) => {
      console.log(error);
      const dispatch = store.dispatch;
      if (error.response.status == 401) dispatch(userActions.logout());
      return {};
    });
}

const postPermanentPassword = async (challenge, password) => {
  const apiDomain = api.url;
  return await axios.post(apiDomain + "/account/password/", {
    challengeName: challenge?.challengeName,
    username: challenge?.USER_ID_FOR_SRP,
    session: challenge?.session,
    password: password
  }, {
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": api.apiKey,
      //"Authorization": "Bearer " + token,
    },
    //httpsAgent 
  }).then((auth) => auth.data)
    .catch((error) => {
      console.log(error);
      const dispatch = store.dispatch;
      if (error.response.status == 401) dispatch(userActions.logout());
      return {};
    });
}

const putPassword = async (token, accessToken, previousPassword, newPassword) => {

  //let authToken = {};
  //console.log(authParams)
  const apiDomain = api.url;
  //console.log(apiDomain+"/api/v1/loginSubmit")
  //const httpsAgent = new https.Agent({rejectUnauthorized: false})

  const params = JSON.stringify({
    "previousPassword": previousPassword,
    "newPassword": newPassword,
    "accessToken": accessToken
  });
  return await axios.put(apiDomain + "/account/password", params, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-API-KEY": api.apiKey,
      "Authorization": "Bearer " + token,
    },
    //httpsAgent 
  }).then((res) => res.data
  ).catch((error) => {
    console.log(error);
    return error;
  });
}

export const userService = {
  login,
  getUserDetails,
  getUserTenant,
  postPermanentPassword,
  putPassword
};