import React, { useState, createRef, useEffect } from 'react';
import ProgressBar from '../../components/ProgressBar/ProgressBar.js';
import './Upload.scss'
import DropZoneIcon from '../../assets/images/Upload.png'
import Checkmark from '../../assets/images/Checkmark.svg'
import XOut from '../../assets/images/XOut.png'
import { viewActions } from "../../actions";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { fileService } from '../../services/fileService.js';
import moment from 'moment';

const Upload = () => {

  const [successfullyUploaded, setSuccessfullyUploaded] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [practiceDate, setPracticeDate] = useState(new Date());
  const [highlight, setHighlight] = useState(false)
  const fileInputRef = createRef();
  const auth = useSelector((state) => state.authentication);

  useEffect(() => {
    viewActions.current('Upload')
  })

  const renderProgress = (file) => {
    const progress = uploadProgress[file.name] ? uploadProgress[file.name].percentage : 0
    if (true || uploading || successfullyUploaded) {
      return (
        <div className="ProgressWrapper">
          <div className="ProgressBar">
            <div
              className="Progress"
              style={{ width: progress + '%' }}
            />
          </div>
          <div>{progress + '%'}</div>
          {/*<img
              className="CheckIcon"
              alt="done"
              src="baseline-check_circle_outline-24px.svg"
              style={{
                opacity: progress && progress.state === "done" ? 0.5 : 0
              }}
            />*/}
        </div>
      );
    }
  }
  const onClear = () => {
    setFiles([]);
    setSuccessfullyUploaded(false);
  }
  const renderActions = () => {
    if (successfullyUploaded) {
      onClear();
      return (
        <div className="Actions"></div>
      );
    } else {
      return (
        <div className="Actions">
          <button
            className="upload"
            disabled={files.length < 1 || uploading}
            onClick={uploadFiles}
          >
            UPLOAD
          </button>
          <button
            className="clear"
            disabled={files.length < 1 || uploading}
            onClick={() =>
              onClear()
            }
          >
            CLEAR ALL
          </button>
        </div>
      );
    }
  }
  const uploadFiles = async () => {
    setUploadProgress({});
    setUploading(true);
    const promises = [];
    files.forEach(file => {
      promises.push(sendRequest(file));
    });
    try {
      await Promise.all(promises);

      setSuccessfullyUploaded(true);
      setUploading(false);
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
    }
  }

  const sendRequest = async (file) => {
    return new Promise(async (resolve, reject, dispatch) => {

      const req = new XMLHttpRequest();
      let duration;
      let audio;

      if (file.type.split('/')[0] == 'audio') {

        audio = new Audio(URL.createObjectURL(file));

        audio.onloadedmetadata = (event) => {
          duration = audio.duration;
        }
      }

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          setUploadProgress(copy);
        }
      });
      req.upload.addEventListener("load", event => {
        const copy = { ...uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        setUploadProgress(copy);
        resolve(req.response);
      });
      req.upload.addEventListener("error", event => {
        const copy = { ...uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        setUploadProgress(copy);
        reject(req.response);
      });
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          if (req.status == 200) {
            //let date = practiceDate;
            let pracDate = moment(practiceDate).format('M/D/YYYY');
            //let options = { year: 'numeric', month: 'long', day: 'numeric' };
            //date = date.toLocaleString('en-US', options);
            fileService.postFile(file.name, file.type, duration, file.size, pracDate, auth)
          }
        }
      }

      let uploadUrl;
      try {
        uploadUrl = await fileService.getSignedUploadUrl(file.name, file.type, auth);
      }
      catch (err) {

      }

      req.open("PUT", uploadUrl);
      req.setRequestHeader("Content-Type", file.type)
      req.send(file);
    });
  }
  const openFileDialog = () => {
    if (uploading || successfullyUploaded) return;
    fileInputRef.current.click();
  }
  const onFilesAdded = (evt) => {
    if (uploading || successfullyUploaded) return;
    const files = evt.target.files;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      setFiles(array);
    }
  }
  const fileListToArray = (list) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }
  const onDragOver = (e) => {
    e.preventDefault();
    if (uploading || successfullyUploaded) return;

    setHighlight(true);
  }
  const onDragLeave = () => {
    setHighlight(false);
  }
  const onDrop = (e) => {
    e.preventDefault();

    if (uploading || successfullyUploaded) return;

    const files = e.dataTransfer.files;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      setFiles(array);
    }
    setHighlight(false);
  }
  return (
    <div className="upload-container">

      <div id="dropzone-container">
        <div className="title">Upload</div>
        <div id="date-picker-container">
          <DatePicker selected={practiceDate} onChange={date => { setPracticeDate(date) }} />
        </div>
        <div
          className={highlight ? " Dropzone Highlight" : "Dropzone"} onClick={openFileDialog}
          style={{ cursor: uploading || successfullyUploaded ? "default" : "pointer" }}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div></div>
          <img
            alt="upload"
            className="Icon"
            src={DropZoneIcon}
          />
          <input
            ref={fileInputRef}
            className="FileInput"
            type="file"
            multiple
            onChange={onFilesAdded}
            //onDragOver={this.onDragOver}
            //onDragLeave={this.onDragLeave}
            //onDrop={this.onDrop}
            onClick={openFileDialog}
          />
          <div className='DropZoneText'>CLICK TO UPLOAD OR DRAG 'N DROP</div>
        </div>
        {renderActions()}
      </div>
      <div className="Files">
        {files.map(file => {
          const progress = uploadProgress[file.name] ? uploadProgress[file.name].percentage.toFixed(2) : 0
          return (
            <div key={file.name}>
              {true ?
                <img
                  className="XOut"
                  alt="Cancel"
                  src={XOut}
                />
                : <img
                  className="CheckIcon"
                  alt="done"
                  src={Checkmark}
                  style={{
                    opacity: progress && progress.state === "done" ? 0.5 : 0
                  }}
                />
              }
              <span className="Filename">{file.name}</span>
              {
                true || uploading || successfullyUploaded ?
                  <div className="ProgressWrapper">
                    <div className="ProgressBar">
                      <div
                        className="Progress"
                        style={{ width: progress + '%' }}
                      />
                    </div>
                    <div>{progress + '%'}</div>
                    {/*<img
                                        className="CheckIcon"
                                        alt="done"
                                        src="baseline-check_circle_outline-24px.svg"
                                        style={{
                                        opacity: progress && progress.state === "done" ? 0.5 : 0
                                        }}
                                    />*/}
                  </div>
                  : null
              }
            </div>
          );
        })}
      </div>

    </div>
  )
}
export default Upload
