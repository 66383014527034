import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import PalindromesLogo from "../../assets/images/PalindromesLogo.png"
import ApolloLogo from "../../assets/images/ApolloLogoWhite.png"
import {
    Link,
    useLocation
} from "react-router-dom";
import { theme } from "../../assets/theme";
import './Navigation.scss';
import { audioActions, notesActions, alertActions } from "../../actions";
import './Navigation.scss';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { viewActions } from "../../actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// location.pathname

export default function Navigation() {
    const location = useLocation();
    const auth = useSelector((state) => state.authentication);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    //const [isNavOpen, setIsNavOpen] = useState(false)
    const view = useSelector((state) => state.view);
    const toggleDrawer = () => {
        //setIsNavOpen((prevState) => !prevState)
    }

    //const [counter, setCounter] = useState(0)

    useEffect(() => {
        //These are here so they don't reset when navigating
        if (user.hasOwnProperty("userId")) {
            dispatch(alertActions.request());
            dispatch(audioActions.getAll('audio', auth));
            dispatch(notesActions.getFileIdsFromNotes(auth));
        }
    }, []);

    return (
        <Drawer
            open={view.isNavOpen}
            onClose={() => dispatch(viewActions.toggleNavigation())}
            direction='left'
            className=''
        >
            <div style={{
                backgroundColor: theme.naomiBlue,
                //position: 'absolute',
                //left:0,
                //top: 0,
                //bottom: 0,
                //width: '20%',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '100%',
                //justifyContent: 'space-evenly'

            }}
                className="navContainer"
            >
                <FontAwesomeIcon 
                    icon={faArrowLeft} 
                    style={{color: theme.white, marginTop: theme.verticalSpacing * 2, height: '2em', width: '2em'} }
                    onClick={() => {
                        dispatch(viewActions.toggleNavigation());
                    }}
                /> 
                {/* A "layout route" is a good place to put markup you want to
                share across all the pages on your site, like navigation. */}
                <img
                    style={{
                        width: '70%',
                        alignSelf: 'center',
                        marginTop: theme.verticalSpacing * 4,
                        marginBottom: theme.verticalSpacing * 4
                    }}
                    src={user?.tenant?.logoUrl}
                    alt={user?.tenant?.name}
                    className="logo"
                />
                <nav style={{

                    listStyleType: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: theme.verticalSpacing * 4,
                    width: '50%'

                }}>
                    <ul style={{
                        margin: 0,
                        paddingLeft: 0,
                        width: '100%'
                    }}>
                        <li
                            style={{
                                borderBottomColor: location.pathname == "/audio" || location.pathname == "/" || location.pathname.includes('notes') ? "rgb(255,255,255, .2)" : "rgb(0,0,0, .2)",
                            }}
                        >
                            <Link to="/">Home</Link>
                        </li>
                        {/*<li
                    style={{
                        borderBottomColor: location.pathname == "/manage" ? "rgb(255,255,255, .2)" : "rgb(0,0,0, .2)",
                    }}
                >
                  <Link to="/manage">Manage</Link>
                </li>*/}
                        <li
                            style={{
                                borderBottomColor: location.pathname == "/upload" ? "rgb(255,255,255, .2)" : "rgb(0,0,0, .2)",
                            }}
                        >
                            <Link to="/upload">Upload</Link>
                        </li>
                        <li
                            style={{
                                borderBottomColor: location.pathname == "/account/profile" ? "rgb(255,255,255, .2)" : "rgb(0,0,0, .2)",
                            }}
                        >
                            <Link to="/account/profile">Profile</Link>
                        </li>
                    </ul>
                </nav>
                <img
                    style={{
                        width: 70,
                        height: 70,
                        alignSelf: 'center',
                        opacity: .5,
                        marginBottom: theme.verticalSpacing * 2,
                        marginTop: 'auto',

                    }}
                    className="apollo"
                    src={ApolloLogo}
                />
            </div>
        </Drawer >
    )
}



