import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordActions } from "../../../actions";
import "./SubmitUsername.scss"
import {
  useNavigate
} from "react-router-dom";
export default function SubmitUsername() {
  //const [touchIdEnabled, setTouchIdEnabled] = useState(user.touchIdEnabled);
  //const toggleSwitch = () => setTouchIdEnabled(previousState => !previousState);
  //const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const forgotPassword = useSelector((state) => state.forgotPassword);


  const handleSubmit = () => {
    if (!!username) {
      dispatch(forgotPasswordActions.postPasswordCode(username))
    }
    else {
      setShowValidationMessage(true)
    }
  }

  useEffect(() => {
    forgotPasswordActions.clear()
  }, [])

  useEffect(() => {
    !!forgotPassword.postPasswordCodeSuccess && navigate("/forgot-password/enter-code");
  }, [forgotPassword.postPasswordCodeSuccess])

  return (

    <>
      <div className="form">
        <div className="form-group">
          {showValidationMessage ? <div className="form-validation-error">Username or email is required</div> : null}
          <input
            type="text"
            className={`form-text-input ${showValidationMessage ? "form-text-input-error " : ""}`}
            autoCapitalize="none"
            autoFocus={false}
            placeholder="Enter your username or email"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <div className="form-label">Username or email</div>
        </div>

        <button
          onClick={handleSubmit}
          className="button"
        >
          Submit
        </button>
        <div className="forgot-password" onClick={() => {
          dispatch(forgotPasswordActions.clear());
          navigate("/login");
        }}>
          Back to Login
        </div>
      </div>
    </>
  );
}
