import { userConstants, audioConstants } from "../constants";
import { userService } from "../services/userService";
import { alertActions } from "./alertActions";

export const userActions = {
  login,
  //modify,
  logout,
  getTenant,
  postPermanentPassword,
  putPassword
};

function login(username, password, touchAuth = false) {
  return (dispatch) => {
    //dispatch(alertActions.request());
    userService.login(username, password, touchAuth)
      .then((auth) => {
        if (auth.success) {
          dispatch(success(auth));
          !!auth?.token && dispatch(getUserDetails(auth, touchAuth));
          !!auth?.token && dispatch(getTenant(auth));
        }
        else {
          const errorMessage = 'Login failed. Please try again.';
          throw errorMessage;
        }
      }).catch((error) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };

  function success(auth) {
    return { type: userConstants.LOGIN_SUCCESS, auth };
  }

  function failure() {
    return { type: userConstants.LOGIN_FAILURE };
  }
}

/*function modify(user) {
  return (dispatch) => {
    if (user) {
      dispatch(success(user));
    } else {
      dispatch(failure());
      dispatch(alertActions.error("User not found"));
    }
  };

  function success(user) {
    return { type: userConstants.MODIFY_SUCCESS, user };
  }
  function failure() {
    return { type: userConstants.MODIFY_FAILURE };
  }
}
*/
function logout() {
  return { type: userConstants.LOGOUT }
}

function getTenant(auth) {
  //dispatch(alertActions.request());
  return (dispatch) => {
    userService.getUserTenant(auth.token)
      .then((res) => {
        if (res.data.success) {
          dispatch(success(res.data.tenant));
          //dispatch(alertActions.clear());
        }
        else {
          const errorMessage = "Something went wrong.";
          throw errorMessage;
        }
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      })
  }
  function success(tenant) {
    return { type: userConstants.GET_TENANT_SUCCESS, tenant: tenant }
  }
  function failure() {
    return { type: userConstants.LOGOUT };
  }
}

function getUserDetails(auth, touchAuth) {
  return (dispatch) => {
    userService.getUserDetails(auth.token)
      .then((user) => {
        if (user.data.success) {
          dispatch(success(user.data.details));
          //dispatch(alertActions.clear());
        }
        else {
          const errorMessage = "Something went wrong.";
          throw errorMessage;
        }

      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      })
  }
  function success(user) {
    return { type: userConstants.GETUSER_SUCCESS, user };
  }

  function failure() {
    return { type: userConstants.LOGOUT }
  }
}

function postPermanentPassword(challenge, password) {
  return (dispatch) => {
    userService.postPermanentPassword(challenge, password)
      .then((auth) => {
        if (auth.success) {
          dispatch(success(auth));
          !!auth?.token && dispatch(getUserDetails(auth));
          !!auth?.token && dispatch(getTenant(auth));
        }
        else {
          const errorMessage = "Something went wrong.";
          throw errorMessage;
        }
      }).catch((error) => {
        console.log(error)
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  }
  function success(auth) {
    return { type: userConstants.POST_PASSWORD_SUCCESS, auth };
  }

  function failure() {
    return { type: userConstants.POST_PASSWORD_FAILURE };
  }
};

function putPassword(auth, previousPassword, newPassword) {
  return (dispatch) => {
      //dispatch(dispatch(alertActions.request()));
      userService.putPassword(auth?.token, auth?.accessToken, previousPassword, newPassword)
          .then((res) => {
              if (res.success) {
                  dispatch(success(res))
                  dispatch(alertActions.success("Your password has been updated."));
              }
              else {
                  const errorMessage = "Something went wrong.";
                  throw errorMessage;
              }
          }).catch((error) => {
              dispatch(failure(error));
              dispatch(alertActions.error(error));
          });
  };
  function success(res) {
      return { type: userConstants.PUT_PASSWORD_SUCCESS, auth: {token: res.token, accessToken: res.accessToken} };
  }
  function failure() {
      return { type: userConstants.PUT_PASSWORD_FAILURE,  };
  }
}
